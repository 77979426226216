import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Note } from '../models/note';
import { ApiHelper } from 'src/app/core/services/api-helper';

@Injectable({
    providedIn: 'root'
})
export class NoteService extends BaseService<Note> {
    protected slug = 'notes';

    constructor(http: HttpClient) {
        super(http);
    }

    protected convertToModel(model: any): Note {
        return Note.convertToModel(model);
    }

    private getQueryParams(customerId: string, locationID?: string) {
        if (locationID) {
            return {
                params: {
                    customerid: customerId,
                    locationid: locationID
                }
            };
        } else {
            return {
                params: {
                    customerid: customerId
                }
            };
        }
    }

    public getAll(customerId: string, locationID?: string): Promise<void | Note[]> {
        return this.http.get(this.getRootUrl(null), this.getQueryParams(customerId, locationID))
            .toPromise()
            .then((response: any[]) => response.map(v => this.convertToModel(v)))
            .catch(ApiHelper.handleError);
    }

    public count(customerId: string, locationID?: string): Promise<void | number> {
        return this.http.get(`${this.getRootUrl(null)}/count`, this.getQueryParams(customerId, locationID))
            .toPromise()
            .then(response => response as number)
            .catch(ApiHelper.handleError);
    }

    public deleteMany(customerId: string, locationID?: string): Promise<void | Object> {
        const options: any = this.getQueryParams(customerId, locationID);
        options.responseType = 'text';
        options.observe = 'response' ;
        return this.http.delete(`${this.getRootUrl(null)}`, options)
            .toPromise()
            .catch(ApiHelper.handleError);
    }
}
