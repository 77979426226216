import { Injectable } from '@angular/core';
import { Location } from '../models/location';
import { BaseService } from '../../core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { CustomerKey } from '../models/customer-key';
import { NoteService } from './note.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService extends BaseService<Location> {
    protected slug = 'locations';

    constructor(http: HttpClient,
        private noteService: NoteService) {
        super(http);
    }

    protected getRootUrl(keyPrefixes: CustomerKey): string {
        const customerId: string = keyPrefixes.customerId;
        return `/api/customers/${customerId}/locations`;
    }

    protected convertToModel(model: any): Location {
        return Location.convertToModel(model);
    }

    public delete(id: string, keyPrefixes?: CustomerKey): Promise<void | Object> {
        return this.noteService.deleteMany(keyPrefixes.customerId, id)
            .then(() => super.delete(id, keyPrefixes));
    }
}
