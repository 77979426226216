import { Input, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, OnDestroy, Directive } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Column, NumericColumn, ActionColumn } from '../models/column';
import { RowAction } from '../models/row-action';
import { ColumnPickerDialogComponent } from './column-picker-dialog.component';
import { Confirmation } from '../../core/models/confirmation';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { IModel } from '../../customers/models/IModel';
import { BaseService } from '../../core/services/base.service';
import { TableColumn, TableSettings } from '../../core/models/table-settings';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../core/models/user';
import { take } from 'rxjs/operators';

@Directive()
export abstract class TableComponentBase<TModel extends IModel> implements OnInit, AfterViewInit, OnDestroy {
    public get actionColumnId(): string {
        return 'row-actions'}
    private userSubscription: Subscription;

    protected abstract editDialogComponentRef: any;
    protected abstract prefixKey: any;
    protected abstract get detailCollection(): TModel[];
    protected abstract get deleteConfirmationTitle(): string;

    protected abstract tableId: string;
    public abstract columns: Column<TModel>[];
    public abstract rowActions: RowAction<TModel>[];
    public displayedColumns: (keyof TModel | string)[];

    @Input() public pageSizeOptions: number[] = [5, 10, 25];
    @Input() public pageSize = 5;
    @Input() public isFiltered = true;
    @Output() public rowsChanged: EventEmitter<any> = new EventEmitter();

    public title: string = null;
    public dataSource: MatTableDataSource<TModel> = new MatTableDataSource();

    public get hasTitle(): boolean {
        return this.title != null && this.title.trim().length > 0;
    }
    public get hasRows(): boolean {
        return this.dataSource.data != null && this.dataSource.data.length > 0;
    }
    public get hasNumericColumns(): boolean {
        return this.columns
            .filter(c => this.displayedColumns.some(d => d === c.id))
            .some(c => c instanceof NumericColumn);
    }

    @ViewChild(MatPaginator)
    private paginator: MatPaginator;
    @ViewChild(MatSort)
    private sort: MatSort;

    constructor(protected detailService: BaseService<TModel>,
        protected authService: AuthService,
        protected userService: UserService,
        protected confirmationService: ConfirmationService,
        protected dialog: MatDialog) {

    }

    public ngOnInit() {

        this.userSubscription = this.authService.currentUser
            .subscribe((user: User) => {
                const tableSettings: TableSettings = user == null || user.tableSettings == null
                    ? null
                    : user.tableSettings
                        .find(s => s.tableId === this.tableId);

                if (tableSettings == null || tableSettings.columns.length === 0) {
                    this.setDisplayedColumns(this.columns);
                } else {
                    this.displayedColumns = tableSettings.columns
                        .map(c => c.columnId)
                        .filter(c => this.columns.some(d => d.id === c));
                    for (const column of this.columns) {
                        column.isVisible = this.displayedColumns.includes(column.id);
                    }
                    this.addActionColumnIfNeeded();
                }
            });
    }

    public ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private resetColumns() {
        for (const column of this.columns) {
            column.isVisible = column.isVisibleByDefault;
        }
    }

    public ngOnDestroy() {
        if (this.userSubscription != null) {
            this.userSubscription.unsubscribe();
        }
    }

    protected abstract getDeleteConfirmationMessage(row: TModel): string;

    protected populateRows() {
        this.setRows(this.detailCollection);
    }

    private setDisplayedColumns(columns: Column<TModel>[]) {
        if (columns != null) {
            this.displayedColumns = columns
                .filter(c => c.isVisible)
                .map(c => c.id);
            this.addActionColumnIfNeeded();
        }
    }

    private saveDisplayedColumns(columns: Column<TModel>[]) {
        this.authService.currentUser
            .pipe(take(1))
            .subscribe((user: User) => {
                if (user != null) {
                    const tableColumns: TableColumn[] = columns
                        .filter(c => c.isVisible)
                        .map(c => new TableColumn(<string>c.id));

                    const tableSettings: TableSettings = user.tableSettings.find(t => t.tableId === this.tableId);
                    if (tableSettings != null) {
                        const index: number = user.tableSettings.indexOf(tableSettings);
                        user.tableSettings.splice(index, 1);
                    }
                    user.tableSettings.push(new TableSettings(this.tableId, tableColumns));
                    this.userService.update(user._id, user);
                }
            });
    }

    private addActionColumnIfNeeded() {
        if (this.rowActions != null && this.rowActions.length > 0) {
            this.displayedColumns.push(this.actionColumnId);
        }
    }

    public isFirstDisplayedColumn(column: Column<TModel>): boolean {
        return column.id === this.displayedColumns[0];
    }

    public applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        // MatTableDataSource defaults to lowercase matches
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    protected getDefaultDialogConfig(): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        return dialogConfig;
    }

    public showColumnPicker() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.columns;
        this.dialog.open(ColumnPickerDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response: any) => {
                if (response == null) {
                    return;
                }
                let columns: Column<TModel>[] = response.columns;
                if (response.reset) {
                    this.resetColumns();
                    columns = this.columns;
                }
                this.setDisplayedColumns(columns);
                this.saveDisplayedColumns(columns);
            });
    }

    protected onRowClick(row: TModel) {

    }

    protected onRowDblClick(row: TModel) {

    }

    protected setRows(rows: TModel[]) {
        this.dataSource.data = rows.slice();
        this.rowsChanged.emit();
    }

    protected addRow(newRow: TModel) {
        if (newRow != null) {
            this.dataSource.data = this.dataSource.data.concat(newRow);
            this.rowsChanged.emit();
        }
    }

    protected removeRow(row: TModel) {
        const index: number = this.dataSource.data.findIndex((r: TModel) => r === row);
        if (index < 0) {
            console.error('Row not found');
            return;
        }
        const dataCopy: TModel[] = this.dataSource.data.slice();
        dataCopy.splice(index, 1);
        this.dataSource.data = dataCopy;
        this.rowsChanged.emit();
    }

    protected updateRow(row: TModel, updatedRow: TModel) {
        const index: number = this.dataSource.data.findIndex((r: TModel) => r === row);
        if (index < 0) {
            console.error('Row not found');
            return;
        }
        const dataCopy: TModel[] = this.dataSource.data.slice();
        dataCopy.splice(index, 1, updatedRow);
        this.detailCollection.splice(index, 1, updatedRow);
        this.dataSource.data = dataCopy;
        this.rowsChanged.emit();
    }

    protected getEditData(row: TModel): any {
        return row;
    }

    protected getRowFromResponse(response: any): TModel {
        return response;
    }

    protected openEditDialog(row: TModel) {
        const dialogConfig: MatDialogConfig = this.getDefaultDialogConfig();
        dialogConfig.data = this.getEditData(row);
        this.dialog.open(this.editDialogComponentRef, dialogConfig)
            .afterClosed()
            .subscribe((response: any) => {
                if (response != null) {
                    const responseRow: TModel = this.getRowFromResponse(response);
                    responseRow._id = undefined;
                    this.detailService.update(row._id, responseRow, this.prefixKey)
                        .then((updatedModel: TModel) => {
                            if (updatedModel != null) {
                                this.updateRow(row, updatedModel);
                            }
                        });
                }
            });
    }

    protected delete(row: TModel) {
        this.confirmationService.confirm(new Confirmation(
            this.deleteConfirmationTitle,
            this.getDeleteConfirmationMessage(row),
            "Delete",
            () => this.detailService.delete(row._id, this.prefixKey)
                .then((res: Response) => {
                    if (res != null && res.ok) {
                        this.removeRow(row);
                        const index: number = this.detailCollection.indexOf(row);
                        this.detailCollection.splice(index, 1);
                    }
                })
        ));
    }

    public onClickColumn(column: Column<TModel>, row: TModel) {
        if (column instanceof ActionColumn) {
            column.action(row);
        }
    }
}
