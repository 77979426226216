import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../core/models/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GravatarService } from '../services/gravatar.service';
import { LoginService } from 'src/app/core/services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SiteSettingsDialogComponent } from './site-settings-dialog.component';
import { SiteSettingsService } from 'src/app/core/services/site-settings.service';
import { SiteSettings } from 'src/app/core/models/site-settings';

@Component({
    selector: 'bet-user-widget',
    templateUrl: './user-widget.component.html',
    styleUrls: ['./user-widget.component.scss']
})
export class UserWidgetComponent implements OnInit, OnDestroy {

    public get isLoggedIn(): boolean {
        return this.authService.isLoggedIn;
    }

    private _greeting: string;
    public get greeting(): string {
        return this._greeting;
    }

    private _gravatarUrl: string;
    public get gravatarUrl(): string {
        return this._gravatarUrl;
    }

    private userSubscription: Subscription;

    constructor(private authService: AuthService,
        private loginService: LoginService,
        private siteSettingsService: SiteSettingsService,
        private gravatarService: GravatarService,
        private router: Router,
        private dialog: MatDialog) { }

    public ngOnInit() {
        this.userSubscription = this.authService.currentUser
            .subscribe((user: User) => {
                if (this.isLoggedIn) {
                    const imageSize = 48;
                    this._gravatarUrl = this.gravatarService.getImageUrl(user.email, imageSize);
                    this.setGreeting(user);
                } else {
                    this._gravatarUrl = '';
                    this._greeting = '';
                }
            });
    }

    public ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    public profile(): void {
        this.router.navigate(['/profile']);
    }

    public settings(): void {
        this.siteSettingsService.getAll().then((response: SiteSettings[]) => {
            let settings: SiteSettings;
            if (response == null || response.length === 0) {
                settings = null;
            } else {
                if (response.length > 1) {
                    console.error(`Settings limit isn't being respected. Found ${response.length}`);
                }
                settings = response[0];
            }

            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = settings;

            this.dialog.open(SiteSettingsDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((settings: any) => {
                    if (settings != null) {
                        this.siteSettingsService.create(settings);
                    }
                });
        });
    }

    public logOut(): void {
        this.loginService.logout();
    }

    private isBlank(str: string) {
        return str == null || str.trim().length === 0;
    }

    private setGreeting(user: User): void {
        if (user.name != null && !this.isBlank(user.name.firstName)) {
            this._greeting = `Welcome, ${user.name.firstName}!`;
        } else if (!this.isBlank(user.username)) {
            this._greeting = `Welcome, ${user.username}!`;
        } else {
            this._greeting = 'Welcome!';
        }
    }
}
