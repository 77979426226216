<button *ngIf="customer != null" mat-button class="back-button" (click)="back()">
  <mat-icon>chevron_left</mat-icon>
  Back to customers
</button>

<div class="detail-header">
  <div class="detail-header-title">
    <mat-icon class="md-48">business</mat-icon>
    <div>
      <h3 i18n>Company Name</h3>
      <h2>{{ customer?.companyName }}</h2>
    </div>
  </div>
</div>

<mat-tab-group dynamicHeight class="mat-elevation-z8">
  <mat-tab label="Locations ({{customer?.locations?.length}})" i18n-label>
    <bet-location-table [customer]="customer"></bet-location-table>
  </mat-tab>
  <mat-tab label="Notes ({{noteCount | async}})" i18n-label>
    <bet-note-table [filter]="noteFilter" 
                    (rowsChanged)="onNotesChanged()">
    </bet-note-table>
  </mat-tab>
  <mat-tab label="Documents ({{documentCount | async}})" i18n-label>
    <bet-document-table [customer]="customer" 
                        (rowsChanged)="onDocumentsChanged()">
    </bet-document-table>
  </mat-tab>
</mat-tab-group>

<bet-fab-speed-dial [primaryButton]="addLocationButton"
                    [secondaryButtons]="[uploadDocumentButton, addNoteButton]">
</bet-fab-speed-dial>