import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '../../shared/components/dialog.component';
import { SubLocation } from '../models/sublocation';

@Component({
    selector: 'bet-sublocation-dialog',
    templateUrl: './sublocation-dialog.component.html'
})
export class SublocationDialogComponent extends DialogComponentBase implements OnInit {

    public form: UntypedFormGroup;

    constructor(dialogRef: MatDialogRef<SublocationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: SubLocation,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data == null);
    }

    public ngOnInit() {
        this.form = this.formBuilder.group({
            name: [this.data == null ? '' : this.data.name, [ Validators.required ]]
        });
    }
}
