import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from 'src/app/shared/components/dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/core/models/user';
import { PasswordValidator } from '../services/password-validator';

@Component({
    selector: 'bet-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent extends DialogComponentBase implements OnInit  {

    constructor(public dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private user: User,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, user == null);
    }

    ngOnInit() {
        const passwordValidators: any[] = [PasswordValidator.passwordRequirements];
        if (this.isCreating) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            name: this.formBuilder.group({
                firstName: [this.user == null || this.user.name == null ? '' : this.user.name.firstName, [ Validators.required ]],
                lastName: [this.user == null || this.user.name == null ? '' : this.user.name.lastName, [ Validators.required ]],
            }),
            email: [this.user == null ? '' : this.user.email, [ Validators.required ]],
            password: ['', passwordValidators],
            confirmPassword: ['', passwordValidators]
        }, {
            validator: PasswordValidator.matchPassword
        });
    }

    protected getResponse(): User {
        const user: User = User.convertToModel(this.form.value);
        // Whether updating or creating, the user shouldn't be setting the ID
        user._id = undefined;
        // Don't reset table settings
        user.tableSettings = undefined;
        if (user.password.trim().length === 0) {
            // Blank password indicates no change
            user.password = undefined;
        }
        return user;
    }
}
