import { Component, OnInit } from '@angular/core';
import { DialogComponentBase } from 'src/app/shared/components/dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent extends DialogComponentBase implements OnInit {

    constructor(public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, true);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [ Validators.required, Validators.email ]],
        });
    }

}
