export class Button {
    constructor(public icon: Icon,
        public label: string,
        public color: string,
        public action: () => void) {

    }
}

export class Icon {
    constructor(public materialIcon: string,
        public fontSet: string,
        public fontIcon: string) {

    }

    public static createMaterialIcon(svgIcon: string) {
        return new Icon(svgIcon, null, null);
    }

    public static createFontIcon(fontSet: string, fontIcon: string) {
        return new Icon(null, fontSet, fontIcon);
    }
}
