import { IModel } from './IModel';

export class Audit implements IModel {
    constructor(public _id: string,
        public energyUsageKwh: number,
        public type: string,
        public auditDate: Date,
        public updateDate: Date,
        public createDate: Date) {

    }

    public static convertToModel(model: any): Audit {
        if (model == null) {
            return null;
        }
        return new Audit(model._id,
            model.energyUsageKwh,
            model.type,
            new Date(model.auditDate),
            new Date(model.updatedAt),
            new Date(model.createdAt));
    }

    public static createEmpty(): Audit {
        return new Audit(
            null,
            0,
            "",
            null,
            null,
            null);
    }
}
