import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { SiteSettings } from '../models/site-settings';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SiteSettingsService extends BaseService<SiteSettings> {
    protected slug = 'site-settings';

    constructor(http: HttpClient) {
        super(http);
    }

    protected convertToModel(model: any): SiteSettings {
        return SiteSettings.convertToModel(model);
    }
}
