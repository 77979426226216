<button *ngIf="location != null" mat-button class="back-button" (click)="back()">
  <mat-icon>chevron_left</mat-icon>
  Back to {{ location.name }}
</button>

<div class="detail-header">
  <div class="detail-header-title">
    <mat-icon class="md-48">home</mat-icon>
    <div>
      <h3><a mat-button [routerLink]="customerLink">{{ customer?.companyName }}</a> / <a mat-button [routerLink]="locationLink">{{ location?.name }}</a></h3>
      <h2>{{ sublocation?.name }}</h2>
    </div>
  </div>
</div>

<bet-charger-table [sublocation]="sublocation" [sublocationKey]="sublocationKey"></bet-charger-table>

<bet-fab-speed-dial [primaryButton]="addChargerButton"
                    [secondaryButtons]="secondaryButtons">
</bet-fab-speed-dial>