export class Name {
    constructor(public firstName: string,
        public lastName: string) {

    }

    public static convertToModel(model: any): Name {
        if (model == null) {
            return null;
        }
        return new Name(model.firstName, model.lastName);
    }

    public static createEmpty() {
        return new Name('', '');
    }

    public getFormattedName(): string {
        return NameFormatter.getFormattedName(this);
    }
}

export class NameFormatter {
    public static getFormattedName(name: Name) {
        return name == null ? '' : `${name.firstName} ${name.lastName}`;
    }
}
