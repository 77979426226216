<mat-card>
  <mat-card-title>
    Address
    <button mat-icon-button (click)="edit()">
      <mat-icon aria-label="Edit Address" i18n-area-label>edit</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
    <ng-container *ngIf="address != null">
      <span class="street-line-1">{{ address.streetAddress }}</span>
      <span class="street-line-2">{{ address.streetAddress2 }}</span>
      <span class="city">{{ address.city }}</span>
      <span class="state">{{ address.state }}</span>
      <span class="postal-code">{{ address.postalCode }}</span>
      <span class="country">{{ address.country }}</span>
    </ng-container>
    <div *ngIf="address == null">
      No address
    </div>
  </mat-card-content>
</mat-card>
