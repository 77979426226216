import { Component } from '@angular/core';
import { LowCarbonFuelStandardReportExporterService } from '../services/low-carbon-fuel-standard-report-exporter.service';
import { FuelSupplyEquipmentRegistrationReportExporterService } from '../services/fuel-supply-equipment-registration-report-exporter.service';
import { MatDialog } from '@angular/material/dialog';
import { LcfsReportDialogComponent } from './lcfs-report-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'bet-report-widget',
    templateUrl: './report-widget.component.html'
})
export class ReportWidgetComponent {

    public get isLoggedIn(): boolean {
        return this.authService.isLoggedIn;
    }

    constructor(private authService: AuthService,
        private lcfsReportExporter: LowCarbonFuelStandardReportExporterService,
        private fseRegistrationReportExporter: FuelSupplyEquipmentRegistrationReportExporterService,
        private dialog: MatDialog) {

    }

    public requestLcfsQuarterlyReport() {
        this.dialog.open(LcfsReportDialogComponent)
            .afterClosed()
            .subscribe((options: any) => {
                if (options != null) {
                    this.lcfsReportExporter.export(options.state, options.runDate);
                }
            });
    }

    public downloadFfrReport() {
        this.fseRegistrationReportExporter.export();
    }
}
