import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SpinnerOverlayService {

    private _isVisible = false;
    public get isVisible(): boolean {
        return this._isVisible;
    }

    constructor() { }

    public showSpinner() {
        this._isVisible = true;
    }

    public hideSpinner() {
        this._isVisible = false;
    }
}
