import { IModel } from "src/app/customers/models/IModel";

export class SiteSettings implements IModel {
    constructor(
        public _id: string,
        public accountAddress: string,
        public accountPassword: string,
        public sendingAddress: string) {

    }

    public static convertToModel(model: any): SiteSettings {
        return new SiteSettings(model._id,
            model.accountAddress,
            model.password,
            model.sendingAddress);
    }

    public static createEmpty(): SiteSettings {
        return new SiteSettings(
            null,
            '',
            '',
            ''
        );
    }
}
