import { Name } from "./name";
import { TableSettings } from "./table-settings";

export class User {
    constructor(
        public _id: string,
        public username: string,
        public email: string,
        public name: Name,
        public password: string,
        public role: string,
        public tableSettings: TableSettings[]) {

    }

    public static convertToModel(model: any): User {
        return new User(model._id,
            model.username,
            model.email,
            Name.convertToModel(model.name),
            model.password,
            model.role,
            model.tableSettings == null
                ? []
                : model.tableSettings.map(TableSettings.convertToModel));
    }

    public static createEmpty(): User {
        return new User(
            null,
            '',
            '',
            Name.createEmpty(),
            '',
            '',
            []
        );
    }
}
