<h2 mat-dialog-title i18n>Import Sub-Location</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <ngx-mat-file-input formControlName="file" placeholder="Upload file"></ngx-mat-file-input>
        <mat-icon matSuffix>insert_drive_file</mat-icon>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="create()" [disabled]="!form.valid" i18n>
        Import
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>