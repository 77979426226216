import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../core/models/user';
import { GravatarService } from '../services/gravatar.service';
import { Subscription, Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDialogComponent } from './user-dialog.component';
import { UserService } from 'src/app/core/services/user.service';
import { UserTableComponent } from './user-table.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

    @ViewChild(UserTableComponent)
    private userTable: UserTableComponent;

    private _gravatarUrl: string;
    public get currentUser(): Observable<User> {
        return this.authService.currentUser;
    }
    public get gravatarUrl(): string {
        return this._gravatarUrl;
    }

    private userSubscription: Subscription;

    constructor(private authService: AuthService,
        private userService: UserService,
        private gravatarService: GravatarService,
        private dialog: MatDialog) { }

    public ngOnInit() {
        this.userSubscription = this.authService.currentUser
            .subscribe((user: User) => {
                if (this.authService.isLoggedIn) {
                    const imageSize = 150;
                    this._gravatarUrl = this.gravatarService.getImageUrl(user.email, imageSize);
                } else {
                    this._gravatarUrl = '';
                }
            });
    }

    public ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    public openCreateDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(UserDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((user: User) => {
                if (user != null) {
                    if (user.role == null) {
                        user.role = 'admin';
                    }
                    this.userService.create(user)
                        .then((createdUser: User) => {
                            this.userTable.addUser(createdUser);
                        });
                }
            });
    }

    public openEditDialog() {
        this.authService.currentUser
            .pipe(take(1))
            .subscribe((currentUser: User) => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data = currentUser;

                this.dialog.open(UserDialogComponent, dialogConfig)
                    .afterClosed()
                    .subscribe((editedUser: User) => {
                        if (editedUser != null) {
                            this.userService.update(currentUser._id, editedUser)
                                .then((updatedUser: User) => {
                                    this.userTable.updateUser(currentUser, updatedUser);
                                });
                        }
                    });
            });
    }
}
