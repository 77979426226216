<h2 mat-dialog-title i18n>Location</h2>

<mat-dialog-content [formGroup]="form">
    <div class="company-info">
        <mat-form-field>
            <input matInput placeholder="Name" i18n-placeholder
                    formControlName="name" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="FSE ID" i18n-placeholder
                    formControlName="fuelSupplyEquipmentId" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Location ID" i18n-placeholder
                    formControlName="locationId" type="number" />
            <mat-hint i18n>
                For generating the location serial number
            </mat-hint>
        </mat-form-field>
    </div>
    <div class="group">
        <mat-form-field>
            <input matInput placeholder="Utility" i18n-placeholder
                    formControlName="utilityServiceProvider" />
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Start Date"
                            formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div formGroupName="address">
        <mat-form-field>
            <input matInput placeholder="Street Address" i18n-placeholder
                    formControlName="streetAddress" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Street Address, Line 2" i18n-placeholder
                    formControlName="streetAddress2" />
        </mat-form-field>
        <div class="address-group">
            <mat-form-field>
                <input matInput placeholder="City" i18n-placeholder
                        formControlName="city" />
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="State" formControlName="state">
                    <mat-option *ngFor="let s of states" [value]="s.abbreviation">
                        {{s.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="address-group">
            <mat-form-field>
                <input matInput placeholder="Postal Code" i18n-placeholder
                        formControlName="postalCode" />
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="Country" formControlName="country">
                    <mat-option *ngFor="let c of countries" [value]="c.value">
                        {{c.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div formGroupName="coordinates">
        <mat-form-field>
            <input matInput placeholder="Latitude" i18n-placeholder
                    formControlName="latitude" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Longitude" i18n-placeholder
                    formControlName="longitude" />
        </mat-form-field>
    </div>
    <div formGroupName="fleetManager">
        <div class="group-label" i18n>
            Fleet Manager
        </div>
        <div class="fleet-manager-group">
            <mat-form-field>
                <input matInput placeholder="First Name" i18n-placeholder
                        formControlName="firstName" />
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Last Name" i18n-placeholder
                        formControlName="lastName" />
            </mat-form-field>
        </div>
        <div class="fleet-manager-group">
            <mat-form-field>
                <input matInput placeholder="Phone Number" i18n-placeholder
                        formControlName="phoneNumber" type="phone" />
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Email Address" i18n-placeholder
                        formControlName="email" type="email" />
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>