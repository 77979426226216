
export class TableSettings {
    constructor(
        public tableId: string,
        public columns: TableColumn[]) {

    }

    public static convertToModel(model: any): TableSettings {
        return new TableSettings(model.tableId,
            model.columns == null
                ? []
                : model.columns.map(TableColumn.convertToModel));
    }

    public static createEmpty(): TableSettings {
        return new TableSettings('', []);
    }
}

export class TableColumn {
    constructor(public columnId: string) {

    }

    public static convertToModel(model: any): TableColumn {
        return new TableColumn(model.columnId);
    }

    public static createEmpty(): TableColumn {
        return new TableColumn('');
    }
}
