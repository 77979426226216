<mat-toolbar color="accent">
  <div class="navbar-brand" (click)="home()">
    <img class="navbar-icon" alt="{{title}} Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIyLjEzMjI2aW4iIGhlaWdodD0iMi4xMzIxOWluIiB2ZXJzaW9uPSIxLjEiIHN0eWxlPSJzaGFwZS1yZW5kZXJpbmc6Z2VvbWV0cmljUHJlY2lzaW9uOyB0ZXh0LXJlbmRlcmluZzpnZW9tZXRyaWNQcmVjaXNpb247IGltYWdlLXJlbmRlcmluZzpvcHRpbWl6ZVF1YWxpdHk7IGZpbGwtcnVsZTpldmVub2RkOyBjbGlwLXJ1bGU6ZXZlbm9kZCIKdmlld0JveD0iMCAwIDIxMzcuODkgMjEzNy44MiIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KIDxkZWZzPgogIDxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+CiAgIDwhW0NEQVRBWwogICAgLmZpbDAge2ZpbGw6IzAwNzBBQztmaWxsLXJ1bGU6bm9uemVyb30KICAgIC5maWwxIHtmaWxsOndoaXRlO2ZpbGwtcnVsZTpub256ZXJvfQogICBdXT4KICA8L3N0eWxlPgogPC9kZWZzPgogPGcgaWQ9Il9feDAwMjNfTGF5ZXJfeDAwMjBfMSI+CiAgPG1ldGFkYXRhIGlkPSJDb3JlbENvcnBJRF8wQ29yZWwtTGF5ZXIiLz4KICA8cGF0aCBjbGFzcz0iZmlsMCIgZD0iTTAuMDYgMTA2OC45MmMwLC01OTAuMzMgNDc4LjU1LC0xMDY4Ljg4IDEwNjguODksLTEwNjguODggNTkwLjM0LDAgMTA2OC44OSw0NzguNTUgMTA2OC44OSwxMDY4Ljg4IDAsNTkwLjMyIC00NzguNTQsMTA2OC44NiAtMTA2OC44OSwxMDY4Ljg2IC01OTAuMzQsMCAtMTA2OC44OSwtNDc4LjU0IC0xMDY4Ljg5LC0xMDY4Ljg2eiIvPgogIDxwb2x5Z29uIGNsYXNzPSJmaWwxIiBwb2ludHM9IjExNTkuNjUsNDA2Ljg3IDE2OTYuNzQsNDA2LjkgMTY5Ni43NCw1MDQuNDcgMTI2NS43OSw1MDQuNDcgMTI2NS43MywxMjg3LjU0IDE2ODEuNCwxMjg3LjU0IDE2ODEuNCwxMzkwLjU1IDEyNjUuNzMsMTM5MC41NSAxMjY1LjczLDE2MjcuOTMgMTY4Mi42NSwxNjI3LjkzIDE2ODIuNjUsMTczMC45NyAxMTU5LjcsMTczMC45NyAxMTU5LjY3LDEzODcuOTEgNDQxLjE5LDEzODcuOTEgNDQxLjE5LDEyOTAuMzIgMTE1OS42NiwxMjkwLjMyICIvPgogIDxwYXRoIGNsYXNzPSJmaWwxIiBkPSJNNDQxLjE3IDEyMDcuMDlsMCAtODAwLjIzIDU1My42MSAwIDExOS4zMSAxMTYuNTUgMCA1OTguODIgLTExOS40MyAxMTYuMzQgLTU1My40NiAwIC0wLjAzIC0zMS40OXptNTY2Ljc5IC0zMzEuNjNsMCAwIC00NjAuNjYgMCAwIDI2NS41MyA0MDguNDggMCA1Mi4xOCAtNTIuMTQgMCAtMjEzLjM5em0tNDYwLjY2IC05Ny41OGwwIDAgNDYwLjY2IDAgMCAtMjIxLjE1IC01My4wMiAtNTIuMyAtNDA3LjY0IDAgMCAyNzMuNDV6Ii8+CiA8L2c+Cjwvc3ZnPg==">
    <div class="navbar-brand-name">{{ title }}</div>
  </div>
  <span class="navbar-spacer"></span>
  <bet-report-widget></bet-report-widget>
  <bet-user-widget></bet-user-widget>
</mat-toolbar>
<div class="content">
  <bet-notification></bet-notification>
  <bet-confirmation></bet-confirmation>
  <router-outlet></router-outlet>
</div>
<div *ngIf="showSpinner" class="spinner-overlay">
  <mat-spinner color="primary"></mat-spinner>
</div>