import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from '../../core/services/notifier.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationDialogComponent } from './notification-dialog.component';
import { Notification } from 'src/app/core/models/notification';

@Component({
    selector: 'bet-notification',
    template: '',
})
export class NotificationComponent implements OnInit, OnDestroy {

    private notificationsSubscription: Subscription;

    public get notifications(): Notification[] {
        return this.notifierService.notifications;
    }

    constructor(private dialog: MatDialog,
        private notifierService: NotifierService) {

    }

    public ngOnInit() {
        this.notificationsSubscription = this.notifierService.messageAdded.subscribe(() => {

            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = true;
            dialogConfig.width = '350px';

            this.dialog.open(NotificationDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe(() => {
                    this.notifierService.clear();
                });
        });
    }

    public ngOnDestroy() {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
    }
}
