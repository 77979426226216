import { Component, OnInit, ViewChild } from '@angular/core';
import { Column } from '../../shared/models/column';
import { RowAction } from '../../shared/models/row-action';
import { User } from '../../core/models/user';
import { UserService } from '../../core/services/user.service';
import { TableComponentBase } from '../../shared/components/table.component';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { AuthService } from '../../core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from './user-dialog.component';
import { take } from 'rxjs/operators';
import { NameFormatter } from 'src/app/core/models/name';

@Component({
    selector: 'bet-user-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class UserTableComponent extends TableComponentBase<User> implements OnInit {
    private users: User[];

    public get isAdmin(): boolean {
        return this.authService.isAdmin;
    }

    protected tableId = 'user-table';
    protected editDialogComponentRef = UserDialogComponent;
    protected prefixKey: any = null;
    protected get detailCollection(): User[] {
        return this.users;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete User';
    }
    public columns: Column<User>[] = [
        new Column<User>('_id', 'ID', false),
        new Column<User>('name', 'Name', true, NameFormatter.getFormattedName),
        new Column<User>('email', 'Email', true),
        new Column<User>('role', 'Role', true),
    ];
    public rowActions: RowAction<User>[] = [
        new RowAction<User>('edit', 'Edit User', this.openEditDialog.bind(this)),
        new RowAction<User>('delete', 'Delete User', this.delete.bind(this))
    ];

    constructor(authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(userService, authService, userService, confirmationService, dialog);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.loadUsers();
    }

    private loadUsers() {
        this.userService.getAll(null)
            .then((users: User[]) => {
                this.users = users.filter(u => u != null);
                this.setRows(this.users);
            });
    }

    protected updateRow(row: User, updatedRow: User) {
        const user: User = this.users.find(u => u == row || u._id === row._id);
        super.updateRow(user, updatedRow);
        this.authService.currentUser
            .pipe(take(1))
            .toPromise()
            .then((currentUser: User) => {
                if (currentUser._id === row._id) {
                    this.authService.loadCurrentUser();
                }
            });
    }

    public addUser(newUser: User) {
        this.addRow(newUser);
    }

    public updateUser(user: User, updatedUser: User) {
        this.updateRow(user, updatedUser);
    }

    protected getDeleteConfirmationMessage(row: User): string {
        return `Are you sure you want to delete user ${row.username}?`;
    }
}
