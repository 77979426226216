export class Coordinates {
    constructor(public latitude: number,
        public longitude: number) {

    }

    public static convertToModel(model: any): Coordinates {
        if (model == null) {
            return null;
        }
        return new Coordinates(model.latitude, model.longitude);
    }

    public static createEmpty(): Coordinates {
        return new Coordinates(0, 0);
    }
}
