<div (betClickOutside)="onMouseLeave()">
    <div class="secondary-action-buttons" [class.active]="isExpanded">
        <button *ngFor="let sb of secondaryButtons" mat-mini-fab 
                [color]="sb.color || 'primary'" class="fab-speed-dial-secondary"
                (click)="sb.action()"
                [matTooltip]="sb.label" matTooltipPosition="left">
            <mat-icon [fontSet]="sb.icon.fontSet" 
                    [fontIcon]="sb.icon.fontIcon"
                    [attr.aria-label]="sb.label">
                    {{ sb.icon.materialIcon }}
            </mat-icon>
        </button>
    </div>

    <button mat-fab class="fab-speed-dial-primary" [class.active]="isExpanded"
            [color]="primaryButton.color || 'primary'"
            (mouseenter)="onMouseEnter()"
            (touchend)="onTouchEnd($event)"
            (click)="onClickPrimary()"
            (blur)="onBlurPrimary()"
            [matTooltip]="primaryButton.label" matTooltipPosition="left">
        <mat-icon *ngIf="primaryButton.icon" class="active-icon"
                [fontSet]="primaryButton.icon.fontSet"
                [fontIcon]="primaryButton.icon.fontIcon"
                [attr.aria-label]="primaryButton.label">
                {{ primaryButton.icon.materialIcon }}
        </mat-icon>
        <mat-icon class="default-icon">add</mat-icon>
    </button>
</div>