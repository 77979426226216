import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from '../models/address';
import { State } from '../../core/models/state';
import { SelectItem } from '../../core/models/select-item';
import { StateService } from '../../core/services/state.service';

@Component({
    selector: 'bet-address-dialog',
    templateUrl: './address-dialog.component.html'
})
export class AddressDialogComponent implements OnInit {

    public form: UntypedFormGroup;
    public states: State[];
    public countries: SelectItem[];

    constructor(private stateService: StateService,
        public dialogRef: MatDialogRef<AddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public address: Address,
        private formBuilder: UntypedFormBuilder) {

    }

    public ngOnInit() {
        if (this.address == null) {
            this.address = Address.createEmpty();
        }

        this.countries = [
            new SelectItem('United States', 'USA')
        ];

        this.stateService.get()
            .then((s: State[]) => {
                this.states = s;
            });
        console.log('Country: ' + this.address.country);
        this.form = this.formBuilder.group({
            streetAddress: [this.address.streetAddress, [Validators.required]],
            streetAddress2: [this.address.streetAddress2],
            city: [this.address.city, [Validators.required]],
            state: [this.address.state, [Validators.required]],
            postalCode: [this.address.postalCode, [Validators.required]],
            country: [this.address.country || this.countries[0].value, [Validators.required]]
        });
    }

    public save() {
        this.dialogRef.close(this.form.value);
    }

    public cancel() {
        this.dialogRef.close();
    }
}
