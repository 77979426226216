import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {
    private get tokenKey(): string {
        return 'x-access-token';
    }

    public get hasToken(): boolean {
        return this.get() != null;
    }

    public get(): string {
        return localStorage.getItem(this.tokenKey);
    }

    public set(token: string) {
        return localStorage.setItem(this.tokenKey, token);
    }

    public clear() {
        return localStorage.removeItem(this.tokenKey);
    }
}
