import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '../../shared/components/dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Charger } from '../models/charger';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { Audit } from '../models/audit';
import { AuditDialogResponse } from '../models/audit-dialog-response';

@Component({
    selector: 'app-charger-audit-dialog',
    templateUrl: './charger-audit-dialog.component.html',
    styleUrls: ['./charger-audit-dialog.component.scss']
})
export class ChargerAuditDialogComponent extends DialogComponentBase implements OnInit {

    private chargerControl: UntypedFormControl;
    private chargers: Charger[];
    public filteredChargers: Observable<Charger[]>;
    public hasPresetCharger: boolean;

    constructor(public dialogRef: MatDialogRef<ChargerAuditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data.audit == null);
    }

    public ngOnInit() {
        let auditDate: Date = new Date();
        let chargerId: string = this.data.chargerId;
        let energyUsage: number;
        if (this.data.audit != null) {
            energyUsage = this.data.audit.energyUsageKwh;
            if (this.data.audit.auditDate != null) {
                auditDate = this.data.audit.auditDate;
            }
            if (chargerId == null && this.data.chargers.length == 1) {
                chargerId = this.data.chargers[0]._id;
            }
        }

        this.hasPresetCharger = chargerId != null;

        this.chargers = this.data.chargers;
        const selectedCharger = this.chargers.find(c => c._id === chargerId);
        this.chargerControl = new UntypedFormControl('charger', [ Validators.required ]);
        this.chargerControl.setValue(selectedCharger);
        this.filteredChargers = this.chargerControl.valueChanges
            .pipe(
                startWith<string | Charger>(''),
                map(value => typeof value === "string" ? value : value.serialNumber),
                map(valueOrSerial => valueOrSerial ? this.filter(valueOrSerial) : this.chargers.slice())
            );

        this.form = this.formBuilder.group({
            charger: this.chargerControl,
            audit: this.formBuilder.group({
                auditDate: [auditDate == null ? new Date() : auditDate, [ Validators.required ]],
                energyUsageKwh: [energyUsage, [ Validators.required ]]
            })
        });
    }

    public display(charger?: Charger): string | undefined {
        return charger ? charger.serialNumber : undefined;
    }

    protected getResponse() {
        const audit: Audit = Audit.convertToModel(this.form.get("audit").value);
        if (moment.isMoment(audit.auditDate)) {
            audit.auditDate = audit.auditDate.toDate();
        }
        const charger: Charger = Charger.convertToModel(this.form.get("charger").value);
        return new AuditDialogResponse(audit, charger);
    }

    private filter(name: string): Charger[] {
        const filterValue = name.toLowerCase();

        return this.chargers.filter(c => c.serialNumber.toLowerCase().includes(filterValue) ||
        c.modelNumber.toLowerCase().includes(filterValue));
    }
}
