import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from '../../core/services/notifier.service';

@Component({
    selector: 'bet-notification-dialog',
    templateUrl: './notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {

    public get title(): string {
        return this.notifierService.notifications
            .map(n => n.title)
            .find(() => true);
    }

    public get messages(): string[] {
        return this.notifierService.notifications
            .map(n => n.message);
    }

    constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>,
        public notifierService: NotifierService) {

    }

    public dismiss() {
        this.dialogRef.close();
    }
}
