import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CustomerDocument } from '../models/document';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private http: HttpClient) { }

    protected handleError (error: any) {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : "Server error";
        console.error(errMsg); // log to console instead
    }

    private convertToModel(model: any): CustomerDocument {
        return CustomerDocument.convertToModel(model);
    }

    private getRootUrl(customerId: string) {
        return `/api/customers/${customerId}/documents`;
    }

    public create(customerId: string, newFile: any, description: string): Promise<void | CustomerDocument> {
        const formData: FormData = new FormData();
        formData.append('description', description);
        formData.append('document', newFile, newFile.name);
        return this.http.post(this.getRootUrl(customerId), formData)
            .toPromise()
            .then(this.convertToModel)
            .catch(this.handleError);
    }

    public getAll(customerId: string): Promise<void | CustomerDocument[]> {
        return this.http.get(this.getRootUrl(customerId))
            .toPromise()
            .then((response: any[]) => response.map(v => this.convertToModel(v)))
            .catch(this.handleError);
    }

    public count(customerId: string): Promise<void | number> {
        return this.http.get(`${this.getRootUrl(customerId)}/count`)
            .toPromise()
            .then(response => response as number)
            .catch(this.handleError);
    }

    public get(customerId: string, document: CustomerDocument): Promise<void | HttpResponse<Blob>> {
        return this.http.get(`${this.getRootUrl(customerId)}/${document.fileName}`,
            { responseType: 'blob', observe: 'response' })
            .toPromise()
            .catch(this.handleError);
    }

    public delete(customerId: string, document: CustomerDocument): Promise<void | Object> {
        return this.http.delete(`${this.getRootUrl(customerId)}/${document.fileName}`,
            { responseType: 'text', observe: 'response' })
            .toPromise()
            .catch(this.handleError);
    }

    public deleteMany(customerId: string): Promise<void | Object> {
        return this.http.delete(`${this.getRootUrl(customerId)}`,
            { responseType: 'text', observe: 'response' })
            .toPromise()
            .catch(this.handleError);
    }
}
