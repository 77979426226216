import { Component, OnInit } from '@angular/core';
import { PasswordValidator } from '../services/password-validator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResetTokenService } from '../services/reset-token.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    private resetTokenId: string;
    private resetToken: string;
    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
        private resetTokenService: ResetTokenService,
        private activatedRoute: ActivatedRoute) { }

    public ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', [ Validators.required, PasswordValidator.passwordRequirements ]],
            confirmPassword: ['', [ Validators.required, PasswordValidator.passwordRequirements ]]
        }, {
            validator: PasswordValidator.matchPassword
        });

        this.activatedRoute.params.subscribe(params => {
            this.resetTokenId = params['id'];
            this.resetToken = params['token'];
        });
    }

    public submit() {
        this.resetTokenService.resetPassword(this.resetTokenId,
            this.resetToken, this.form.value.password);
    }
}
