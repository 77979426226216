import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { TableComponentBase } from '../../shared/components/table.component';
import { Column, NumericColumn } from '../../shared/models/column';
import { RowAction } from '../../shared/models/row-action';
import { Router } from '@angular/router';
import { DateFormatter } from '../../core/services/date.service';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerKey } from '../models/customer-key';
import { CustomerDialogComponent, CustomerDialogData } from './customer-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'bet-customer-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class CustomerTableComponent extends TableComponentBase<Customer> {
    private _customer: Customer[];
    @Input()
    public get customers(): Customer[] {
        return this._customer;
    }
    public set customers(c: Customer[]) {
        if (c !== this._customer) {
            this._customer = c;
            this.setRows(c == null ? [] : c);
        }
    }

    protected tableId = 'customer-table';
    protected editDialogComponentRef = CustomerDialogComponent;
    protected prefixKey: CustomerKey = null;
    protected get detailCollection(): Customer[] {
        return this.customers;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Customer';
    }
    public columns: Column<Customer>[] = [
        new Column<Customer>('_id', 'ID', false),
        new Column<Customer>('companyName', 'Company Name', true),
        new NumericColumn<Customer>('numberOfLocations', 'Total Number of Locations', true),
        new NumericColumn<Customer>('numberOfForklifts', 'Total Number of Forklifts', true),
        new NumericColumn<Customer>('numberOfSublocations', 'Total Number of Sub-Locations', true),
        new NumericColumn<Customer>('numberOfChargers', 'Total Number of Chargers', true),
        new NumericColumn<Customer>('quarterlyEnergyUsageKwh', 'Quarterly Energy Usage (kWh)', true, true, 'kWh'),
        new Column<Customer>('startDate', 'Date Added', true, DateFormatter.getQuarterDateString),
        new Column<Customer>('formattedAge', 'Age', false),
        new Column<Customer>('createDate', 'Date Created', false, DateFormatter.getLocaleDateString)
    ];
    public rowActions: RowAction<Customer>[] = [
        new RowAction<Customer>('delete', 'Delete Customer', this.delete.bind(this)),
        new RowAction<Customer>('edit', 'Edit Customer', this.openEditDialog.bind(this)),
        new RowAction<Customer>('launch', 'Customer Details', this.redirectToCustomerDetails.bind(this)),
    ];

    constructor(customerService: CustomerService,
        confirmationService: ConfirmationService,
        private router: Router,
        authService: AuthService,
        userService: UserService,
        dialog: MatDialog) {
        super(customerService, authService, userService, confirmationService, dialog);
    }

    protected getDeleteConfirmationMessage(row: Customer): string {
        return `Are you sure you want to delete '${row.companyName}'?`;
    }

    protected onRowDblClick(row: Customer) {
        this.redirectToCustomerDetails(row);
    }

    public addCustomer(newCustomer: Customer) {
        this.addRow(newCustomer);
    }

    private redirectToCustomerDetails(customer: Customer) {
        this.router.navigate(['/customers', customer._id]);
    }

    protected getEditData(row: Customer): any {
        return new CustomerDialogData(this.customers, row);
    }
}
