<h2 mat-dialog-title i18n>User</h2>

<mat-dialog-content [formGroup]="form">
    <div formGroupName="name">
        <mat-form-field>
            <input matInput placeholder="First Name" i18n-placeholder
                    formControlName="firstName" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Last Name" i18n-placeholder
                    formControlName="lastName" />
        </mat-form-field>
    </div>
  <mat-form-field>
      <input matInput placeholder="Email" i18n-placeholder
              formControlName="email" type="email" />
  </mat-form-field>
  <mat-form-field>
      <input matInput placeholder="Password" i18n-placeholder
              formControlName="password" type="password" />
  </mat-form-field>
  <mat-form-field>
      <input matInput placeholder="Confirm Password" i18n-placeholder
              formControlName="confirmPassword" type="password" />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
      Save
  </button>
  <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>