<button *ngIf="customer != null" mat-button class="back-button" (click)="back()">
  <mat-icon>chevron_left</mat-icon>
  Back to {{ customer.companyName }}
</button>
<div class="detail-header">
  <div class="detail-header-title">
    <mat-icon class="md-48">location_on</mat-icon>
    <div>
      <h3><a mat-button [routerLink]="customerLink">{{ customer?.companyName }}</a></h3>
      <h2>{{ location?.name }}</h2>
    </div>
  </div>
  <div class="detail-header-cards">
    <bet-address [address]="location?.address"></bet-address>
  </div>
</div>

<mat-tab-group dynamicHeight class="mat-elevation-z8" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Sub-Locations ({{location?.sublocations?.length}})" i18n-label>
      <bet-sublocation-table [location]="location" [locationKey]="locationKey"></bet-sublocation-table>
  </mat-tab>
  <mat-tab label="Forklifts ({{forklifts?.length || 0}})" i18n-label>
      <bet-forklift-table [location]="location" [locationKey]="locationKey"></bet-forklift-table>
  </mat-tab>
  <mat-tab label="Notes ({{noteCount | async}})" i18n-label>
    <bet-note-table [filter]="noteFilter"
                    (rowsChanged)="onNotesChanged()">
    </bet-note-table>
  </mat-tab>
</mat-tab-group>

<bet-fab-speed-dial [primaryButton]="primaryButton"
                    [secondaryButtons]="secondaryButtons">
</bet-fab-speed-dial>