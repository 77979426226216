import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { SelectItem } from '../../core/models/select-item';
import { Forklift } from '../models/forklift';
import { DialogComponentBase } from '../../shared/components/dialog.component';

@Component({
    selector: 'app-forklift-dialog',
    templateUrl: './forklift-dialog.component.html'
})
export class ForkliftDialogComponent extends DialogComponentBase implements OnInit {

    public fuelTypes: SelectItem[] = [
        new SelectItem('Electric', 'E'),
        new SelectItem('Diesel', 'D'),
        new SelectItem('Propane', 'P'),
        new SelectItem('Hydrogen', 'H')
    ];

    constructor(public dialogRef: MatDialogRef<ForkliftDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: Forklift,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data == null);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            make: [this.data == null ? '' : this.data.make, [Validators.required]],
            model: [this.data == null ? '' : this.data.model],
            modelYear: [this.data == null ? '' : this.data.modelYear, [Validators.required]],
            serialNumber: [this.data == null ? '' : this.data.serialNumber, [Validators.required]],
            fuelType: [this.data == null ? '' : this.data.fuelType, [Validators.required]],
        });
    }
}
