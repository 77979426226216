import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/core/services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog.component';
import { ResetTokenService } from '../services/reset-token.service';

@Component({
    selector: 'bet-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private resetTokenService: ResetTokenService,
        private dialog: MatDialog) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [ Validators.required, Validators.email ]],
            password: ['', [ Validators.required ]]
        });
    }

    public openResetRequestDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(ForgotPasswordDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((resetRequest: any) => {
                if (resetRequest != null) {
                    this.resetTokenService.sendResetRequest(resetRequest.email);
                }
            });
    }

    public submit() {
        this.loginService.login(this.form.value.email, this.form.value.password);
    }
}
