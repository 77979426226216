import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '../models/customer';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DialogComponentBase } from '../../shared/components/dialog.component';
import { Customers } from '../models/customers';

export class CustomerDialogData {
    constructor(public customers?: Customer[],
        public customer?: Customer) {

    }
}

@Component({
    selector: 'bet-customer-dialog',
    templateUrl: './customer-dialog.component.html',
    styleUrls: ['./customer-dialog.component.scss']
})
export class CustomerDialogComponent extends DialogComponentBase implements OnInit {

    public form: UntypedFormGroup;
    public newCustomer: Customer = Customer.createEmpty();

    constructor(public dialogRef: MatDialogRef<CustomerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: CustomerDialogData,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data == null);
    }

    public ngOnInit() {
        const hasCustomer: boolean = this.data.customer == null;
        const customer: Customer = this.data.customer;
        this.form = this.formBuilder.group({
            customerId: [hasCustomer ? null : customer.customerId, [ Validators.required, CustomerDialogComponent.unique(this.data) ]],
            companyName: [hasCustomer ? '' : customer.companyName, [ Validators.required ]],
            startDate: [hasCustomer ? '' : customer.startDate, [ Validators.required ]]
        });
    }

    private static unique(data: CustomerDialogData) {
        return (control: AbstractControl) => {
            const isDuplicateId: boolean = data.customers != null && data.customers
                .filter(c => c !== data.customer)
                .map(c => c.customerId)
                .some(id => id === control.value);
            return isDuplicateId ? { UniqueId: isDuplicateId } : null;
        };
    }
}
