<h2 mat-dialog-title i18n>
  Choose Columns
  <button mat-icon-button>
    <mat-icon aria-label="Reset displayed columns" (click)="reset()">history</mat-icon>
  </button>
</h2>
  
<mat-dialog-content [formGroup]="form">
  <mat-checkbox *ngFor="let column of columns" [formControlName]="column.id">
    {{column.label}}
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="save()" i18n>Save</button>
  <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>