import { Injectable } from '@angular/core';
import { SubLocation } from '../models/sublocation';
import * as XLSX from 'xlsx';
import { FileInput } from 'ngx-material-file-input';
import { Charger } from '../models/charger';
import { Audit } from '../models/audit';
import { SublocationService } from './sublocation.service';
import { LocationKey } from '../models/location-key';
import { NotifierService } from '../../core/services/notifier.service';
import * as moment from 'moment/moment';

class ValidationCell {
    public actualValue: string;

    constructor(public cellId: string,
        public expectedValue: string,
        worksheet: XLSX.WorkSheet,
        public usePrefixComparison: boolean = false) {
        const cell: XLSX.CellObject = worksheet[cellId];
        if (cell != null) {
            this.actualValue = cell.w;
        }
    }

    public get isValid(): boolean {
        if (this.usePrefixComparison) {
            return this.actualValue != null &&
               this.actualValue.startsWith(this.expectedValue);
        } else {
            return this.actualValue === this.expectedValue;
        }
    }

    public get invalidMessage(): string {
        return `Invalid cell ${this.cellId}: Expected '${this.expectedValue}', Actual '${this.actualValue}'`;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SublocationImporterService {

    constructor(private sublocationService: SublocationService,
        private notifierService: NotifierService) {

    }

    public import(fileInput: FileInput, locationKey: LocationKey): Promise<void | SubLocation> {
        if (fileInput.files.length !== 1) {
            // TODO: May as well allow multiple imports, right?
            throw 'Exactly one import file is required';
        }

        const reader: FileReader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const workbook: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                /* grab first sheet */
                const wsname: string = workbook.SheetNames[0];
                const worksheet: XLSX.WorkSheet = workbook.Sheets[wsname];

                const validationErrors: string[] = this.validateFileFormat(worksheet);
                if (validationErrors.length > 0) {
                    const message: string = "Invalid file format:\n" + validationErrors.join(",\n");
                    this.notifierService.error(message);
                    return Promise.reject(message);
                }

                /* save data */
                const newSublocation: SubLocation = this.getSublocation(worksheet);
                if (newSublocation == null) {
                    return;
                }
                this.sublocationService.create(newSublocation, locationKey)
                    .then((createdSublocation: SubLocation) => {
                        resolve(createdSublocation);
                    });
            };
            reader.onerror = () => reject(this);
            reader.readAsBinaryString(fileInput.files[0]);
        });
    }

    private validateFileFormat(worksheet: XLSX.WorkSheet): string[] {
        const validationCells: ValidationCell[] = [
            new ValidationCell('A1', 'CUSTOMER NAME', worksheet),
            new ValidationCell('A3', 'ADDRESS', worksheet),
            new ValidationCell('A4', 'CHARGER LOCATION', worksheet),
            new ValidationCell('A5', 'SITE VISIT DATE', worksheet),
            new ValidationCell('A7', 'CHARGER MODEL', worksheet),
            new ValidationCell('B7', 'CHARGER SERIAL', worksheet),
            new ValidationCell('C7', 'BATTERY MODEL', worksheet),
            new ValidationCell('D7', 'KILOWATTS USED', worksheet, true),
        ];

        const errors: string[] = [];
        for (const v of validationCells) {
            if (!v.isValid) {
                errors.push(v.invalidMessage);
            }
        }
        return errors;
    }

    private getSublocation(worksheet: XLSX.WorkSheet): SubLocation {
        const firstChargerRow = 8;

        const sublocationNameCellId = 'B4';
        const siteVisitDateCellId = 'B5';

        const sublocationNameCell = worksheet[sublocationNameCellId];
        const siteVisitDateCell = worksheet[siteVisitDateCellId];

        if (sublocationNameCell == null) {
            this.notifierService.error('Import file missing sub-location name');
            return null;
        }
        if (siteVisitDateCell == null) {
            this.notifierService.error('Import file missing site visit date');
            return null;
        }

        const sublocationName: string = sublocationNameCell == null ? "" : sublocationNameCell.w;

        const siteVisitCellValue: string | Date = siteVisitDateCell == null ? null : siteVisitDateCell.w;
        const siteVisitDate: Date = siteVisitCellValue == null
            ? null
            : siteVisitCellValue instanceof Date
                ? siteVisitCellValue
                : moment(siteVisitCellValue).toDate();

        const chargers: Charger[] = [];
        let chargerRow: number = firstChargerRow;
        let testCell: XLSX.CellObject = worksheet['A' + chargerRow];
        while (testCell != null && testCell.v != '' && testCell.w.toLowerCase() != 'total') {
            chargers.push(this.getCharger(worksheet, chargerRow, siteVisitDate));
            testCell = worksheet['A' + ++chargerRow];
        }

        return new SubLocation(undefined, sublocationName, chargers);
    }

    private getCharger(worksheet: XLSX.WorkSheet, row: number, siteVisitDate: Date): Charger {
        const modelNumberColumn = 'A';
        const serialNumberColumn = 'B';
        const batteryModelColumn = 'C';
        const energyUsageColumn = 'D';

        const modelNumber: string = <string>this.getCellValue(worksheet, modelNumberColumn + row);
        const serialNumber: string = <string>this.getCellValue(worksheet, serialNumberColumn + row);
        const batteryModel: string = <string>this.getCellValue(worksheet, batteryModelColumn + row);
        const energyUsage: number = <number>this.getCellValue(worksheet, energyUsageColumn + row);

        const initialAudit: Audit = new Audit(undefined, energyUsage, "Initial", siteVisitDate, undefined, undefined);
        return new Charger(undefined, modelNumber, serialNumber, 'Forklift', [initialAudit], siteVisitDate, undefined);
    }

    private getCellValue(worksheet: XLSX.WorkSheet, cellId: string): string | number | boolean | Date {
        const cell: XLSX.CellObject = worksheet[cellId];
        return cell == null ? null : cell.v;
    }
}
