import { Component, OnInit, ViewChild } from '@angular/core';
import { ChargerKey } from '../models/charger-key';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { Location } from '../models/location';
import { SubLocation } from '../models/sublocation';
import { Charger } from '../models/charger';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ChargerAuditDialogComponent } from './charger-audit-dialog.component';
import { AuditService } from '../services/audit.service';
import { Audit } from '../models/audit';
import { ChargerAuditTableComponent } from './charger-audit-table.component';
import { AuditDialogResponse } from '../models/audit-dialog-response';

@Component({
    selector: 'bet-charger-detail',
    templateUrl: './charger-detail.component.html',
    styleUrls: ['./detail.component.scss', './charger-detail.component.scss']
})
export class ChargerDetailComponent implements OnInit {
    private customer: Customer;

    public chargerKey: ChargerKey;

    private get location(): Location {
        return this.chargerKey == null || this.customer == null
            ? null
            : this.customer.locations.find(loc => loc._id === this.chargerKey.locationId);
    }

    public get sublocation(): SubLocation {
        return this.chargerKey == null || this.location == null
            ? null
            : this.location.sublocations.find(s => s._id === this.chargerKey.sublocationId);
    }

    public get charger(): Charger {
        return this.chargerKey == null || this.sublocation == null
            ? null
            : this.sublocation.chargers.find(c => c._id === this.chargerKey.chargerId);
    }

    public get sublocationLink(): string[] {
        return ['/customers', this.chargerKey.customerId, 'locations', this.chargerKey.locationId, 'sublocations', this.chargerKey.sublocationId];
    }

    @ViewChild(ChargerAuditTableComponent) private chargerAuditTable;

    constructor(private customerService: CustomerService,
        private auditService: AuditService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router) {

    }

    public ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            const chargerKey: ChargerKey = new ChargerKey(params["customerId"],
                params["locationId"],
                params["sublocationId"],
                params["chargerId"]);
            this.chargerKey = chargerKey;
            this.customerService.get(this.chargerKey.customerId)
                .then((c: Customer | void) => {
                    if (c) {
                        this.customer = c;
                    }
                });
        });
    }

    public openCreateAuditDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            chargers: [this.charger],
            chargerId: this.chargerKey.chargerId
        };
        this.dialog.open(ChargerAuditDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response: AuditDialogResponse) => {
                if (response != null) {
                    response.audit.type = 'Audit';
                    this.auditService.create(response.audit, this.chargerKey)
                        .then((createdAudit: Audit) => {
                            if (createdAudit != null) {
                                this.charger.audits.push(createdAudit);
                                this.chargerAuditTable.addRow(createdAudit);
                            }
                        });
                }
            });
    }

    public back() {
        this.router.navigate(this.sublocationLink);
    }
}
