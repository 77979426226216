import { Component, Input } from '@angular/core';
import { TableComponentBase } from '../../shared/components/table.component';
import { Column } from '../../shared/models/column';
import { Note, NoteFilter } from '../models/note';
import { RowAction } from '../../shared/models/row-action';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { DateFormatter } from '../../core/services/date.service';
import { NoteService } from '../services/note.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoteDialogComponent } from './note-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'bet-note-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss', './note-table.component.scss']
})
export class NoteTableComponent extends TableComponentBase<Note> {
    private _filter: NoteFilter;
    private notes: Note[];

    @Input()
    public get filter(): NoteFilter {
        return this._filter;
    }
    public set filter(f: NoteFilter) {
        if ((f == null) !== (this._filter == null) ||
      (f != null && (f.customerId !== this._filter.customerId ||
      f.locationID !== this._filter.locationID))) {
            this._filter = f;
            this.loadNotes();
        }
    }

    protected tableId = 'note-table';
    protected prefixKey: NoteFilter;
    protected editDialogComponentRef = NoteDialogComponent;
    protected get deleteConfirmationTitle(): string {
        return 'Delete Note';
    }
    protected get detailCollection(): Note[] {
        return this.notes;
    }
    public columns: Column<Note>[] = [
        new Column<Note>('_id', 'ID', false),
        new Column<Note>('subject', 'Subject', true),
        new Column<Note>('content', 'Note', true, (content: string) => content ? content.replace(/<[^>]+>/gm, ' ') : ''),
        new Column<Note>('date', 'Date', true, DateFormatter.getLocaleDateString),
    ];
    public rowActions: RowAction<Note>[] = [
        new RowAction<Note>('delete', 'Delete Note', this.delete.bind(this)),
        new RowAction<Note>('edit', 'Edit Note', this.openEditDialog.bind(this))
    ];

    constructor(noteService: NoteService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(noteService, authService, userService, confirmationService, dialog);
    }

    public ngOnInit() {
        super.ngOnInit();
    }

    public addRow(newRow: Note) {
        super.addRow(newRow);
    }

    private loadNotes() {
        if (this.filter != null && this.filter.customerId != null) {
            (<NoteService>this.detailService).getAll(this.filter.customerId, this.filter.locationID)
                .then((notes: Note[]) => {
                    if (notes != null) {
                        this.notes = notes;
                        this.setRows(notes);
                    }
                });
        }
    }

    protected onRowDblClick(row: Note) {
        this.openEditDialog(row);
    }

    protected getDeleteConfirmationMessage(row: Note): string {
        return `Are you sure you want to delete '${row.subject}'?`;
    }

    protected getDefaultDialogConfig(): MatDialogConfig {
        const dialogConfig = super.getDefaultDialogConfig();
        dialogConfig.width = NoteDialogComponent.defaultWidth;
        return dialogConfig;
    }
}
