import { Component, Input } from '@angular/core';
import { TableComponentBase } from '../../shared/components/table.component';
import { Column } from '../../shared/models/column';
import { Customer } from '../models/customer';
import { CustomerDocument } from '../models/document';
import { RowAction } from '../../shared/models/row-action';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { Confirmation } from '../../core/models/confirmation';
import { DocumentService } from '../services/document.service';
import { DateFormatter } from '../../core/services/date.service';
import { DownloadService } from '../../core/services/download.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerKey } from '../models/customer-key';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'bet-document-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class DocumentTableComponent extends TableComponentBase<CustomerDocument> {
    private _customer: Customer;
    private documents: CustomerDocument[];

    @Input()
    public get customer(): Customer {
        return this._customer;
    }
    public set customer(c: Customer) {
        this._customer = c;
        this.loadDocuments();
    }

    protected tableId = 'document-table';
    protected editDialogComponentRef = null;
    protected prefixKey: CustomerKey = null;
    protected get detailCollection(): CustomerDocument[] {
        return this.documents;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Document';
    }
    public columns: Column<CustomerDocument>[] = [
        new Column<CustomerDocument>('_id', 'ID', false),
        new Column<CustomerDocument>('originalFileName', 'File Name', true),
        new Column<CustomerDocument>('description', 'Description', true),
        new Column<CustomerDocument>('fileSizeBytes', 'File Size', true, this.getFormattedFileSize.bind(this)),
        new Column<CustomerDocument>('uploadDate', 'Upload Date', true, DateFormatter.getLocaleDateString),
    ];
    public rowActions: RowAction<CustomerDocument>[] = [
        new RowAction<CustomerDocument>('delete', 'Delete Document', this.delete.bind(this)),
        new RowAction<CustomerDocument>('cloud_download', 'Download Document', this.downloadDocument.bind(this))
    ];

    constructor(private documentService: DocumentService,
        private downloadService: DownloadService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(null, authService, userService, confirmationService, dialog);
    }

    public ngOnInit() {
        super.ngOnInit();
    }

    public addRow(newRow: CustomerDocument) {
        super.addRow(newRow);
    }

    private getFormattedFileSize(fileSizeBytes: number) {
        const units: string[] = ['B', 'KB', 'MB', 'GB'];
        const factor = 1024;
        let unitIndex = 0;
        let fileSize: number = fileSizeBytes;

        if (fileSizeBytes < factor) {
            return fileSize + ' ' + units[unitIndex];
        }

        while (fileSize > factor && unitIndex < units.length) {
            unitIndex++;
            fileSize = fileSize / factor;
        }
        return Math.round(fileSize) + ' ' + units[unitIndex];
    }

    private loadDocuments() {
        if (this.customer != null) {
            this.documentService.getAll(this.customer._id)
                .then((documents: CustomerDocument[]) => {
                    this.documents = documents;
                    this.setRows(documents == null ? [] : documents);
                });
        }
    }

    protected onRowDblClick(row: CustomerDocument) {
        this.downloadDocument(row);
    }

    protected downloadDocument(row: CustomerDocument) {
        this.documentService.get(this.customer._id, row)
            .then((response: HttpResponse<Blob>) => {
                this.downloadService.download(response);
            });
    }

    protected getDeleteConfirmationMessage(row: CustomerDocument): string {
        return `Are you sure you want to delete '${row.originalFileName}'?`;
    }

    protected delete(row: CustomerDocument) {
        this.confirmationService.confirm(new Confirmation(this.deleteConfirmationTitle,
            this.getDeleteConfirmationMessage(row),
            "Delete",
            () => this.documentService.delete(this.customer._id, row)
                .then(() => this.removeRow(row))
        ));
    }
}
