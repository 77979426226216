<h2 mat-dialog-title i18n>Upload Document</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <ngx-mat-file-input formControlName="file" placeholder="Document"></ngx-mat-file-input>
        <mat-icon matSuffix>insert_drive_file</mat-icon>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Description" i18n-placeholder
               formControlName="description" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="create()" [disabled]="!form.valid" i18n>
        Upload
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>