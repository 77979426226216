<button *ngIf="sublocation != null" mat-button class="back-button" (click)="back()">
  <mat-icon>chevron_left</mat-icon>
  Back to {{ sublocation.name }}
</button>

<div class="detail-header">
  <div class="detail-header-title">
    <mat-icon class="md-48">battery_charging_full</mat-icon>
    <div>
      <h3><a mat-button [routerLink]="sublocationLink">{{ sublocation?.name }}</a></h3>
      <h2>{{ charger?.serialNumber }}</h2>
    </div>
  </div>
</div>

<bet-charger-audit-table [chargerKey]="chargerKey" [charger]="charger"></bet-charger-audit-table>

<button mat-fab color="primary" class="md-fab-bottom-right"
        (click)="openCreateAuditDialog()"
        matTooltip="Record Audit" i18n-matTooltip
        matTooltipPosition="left">
    <mat-icon class="default-icon">add</mat-icon>
</button>