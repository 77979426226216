import { Component, OnInit, ViewChild } from '@angular/core';
import { Customer } from '../models/customer';
import { Location } from '../models/location';
import { CustomerService } from '../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SublocationDialogComponent } from './sublocation-dialog.component';
import { SubLocation } from '../models/sublocation';
import { Forklift } from '../models/forklift';
import { Address } from '../models/address';
import { Button, Icon } from '../../shared/models/button';
import { ForkliftDialogComponent } from './forklift-dialog.component';
import { NoteService } from '../services/note.service';
import { NoteDialogComponent } from './note-dialog.component';
import { Note, NoteFilter } from '../models/note';
import { NoteTableComponent } from './note-table.component';
import { LocationKey } from '../models/location-key';
import { SublocationImportDialogComponent } from './sublocation-import-dialog.component';
import { SublocationImporterService } from '../services/sublocation-importer.service';
import { NotifierService } from '../../core/services/notifier.service';
import { SublocationTableComponent } from './sublocation-table.component';
import { SublocationService } from '../services/sublocation.service';
import { ForkliftService } from '../services/forklift.service';
import { ForkliftTableComponent } from './forklift-table.component';
import { DocumentService } from '../services/document.service';

@Component({
    selector: 'bet-location-detail',
    templateUrl: './location-detail.component.html',
    styleUrls: ['./detail.component.scss', './location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {
    public noteCount: Promise<void | number>;
    public noteFilter: NoteFilter;

    private addSublocationButton: Button = new Button(Icon.createFontIcon('fas', 'fa-warehouse'),
        "Add Sub-Location", null, this.openCreateSublocationDialog.bind(this));
    private importSublocationButton: Button = new Button(Icon.createMaterialIcon('import_export'),
        "Import Sub-Location", null, this.openImportSublocationDialog.bind(this));
    private addForkliftButton: Button = new Button(Icon.createFontIcon('fas', 'fa-car'),
        "Add Forklift", null, this.openCreateForkliftDialog.bind(this));
    public addNoteButton: Button = new Button(Icon.createMaterialIcon('notes'),
        "Add Note", null, this.openCreateNoteDialog.bind(this));

    @ViewChild(SublocationTableComponent)
    private sublocationTable: SublocationTableComponent;
    @ViewChild(ForkliftTableComponent)
    private forkliftTable: ForkliftTableComponent;
    @ViewChild(NoteTableComponent)
    private noteTable: NoteTableComponent;

    public locationKey: LocationKey;

    public primaryButton: Button = this.addSublocationButton;
    public secondaryButtons: Button[] = [this.addNoteButton, this.addForkliftButton, this.importSublocationButton];

    public customer: Customer;
    public get location(): Location {
        if (this.customer == null || this.customer.locations == null) {
            return null;
        }
        return this.customer.locations.find(loc => loc._id === this.locationKey.locationId);
    }

    public get forklifts(): Forklift[] {
        return this.location == null ? [] : this.location.forklifts;
    }

    public get customerLink(): string[] {
        return ['/customers', this.locationKey.customerId];
    }

    constructor(private customerService: CustomerService,
        private sublocationService: SublocationService,
        private forkliftService: ForkliftService,
        private noteService: NoteService,
        private sublocationImporterService: SublocationImporterService,
        private documentService: DocumentService,
        private notifierService: NotifierService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router) { }

    public ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.locationKey = new LocationKey(params['customerId'], params['locationId']);
            this.noteFilter = new NoteFilter(this.locationKey.customerId, this.locationKey.locationId);
            this.updateNoteCount(this.locationKey.customerId, this.locationKey.locationId);
            this.customerService.get(this.locationKey.customerId).then((c: Customer | void) => {
                if (c) {
                    this.customer = c;
                }
            });
        });
    }

    private updateNoteCount(customerId: string, locationID: string) {
        this.noteCount = this.noteService.count(customerId, locationID);
    }

    public onTabChange(event: MatTabChangeEvent) {
        switch (event.index) {
        case 2:
            this.primaryButton = this.addNoteButton;
            this.secondaryButtons = [this.importSublocationButton, this.addSublocationButton, this.addForkliftButton];
            break;
        case 1:
            this.primaryButton = this.addForkliftButton;
            this.secondaryButtons = [this.addNoteButton, this.importSublocationButton, this.addSublocationButton];
            break;
        case 0:
        default:
            this.primaryButton = this.addSublocationButton;
            this.secondaryButtons = [this.addNoteButton, this.addForkliftButton, this.importSublocationButton];
            break;
        }
    }

    private getDialogConfig(): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        return dialogConfig;
    }

    public openCreateSublocationDialog() {
        this.dialog.open(SublocationDialogComponent, this.getDialogConfig())
            .afterClosed()
            .subscribe((newSublocation: SubLocation) => {
                if (newSublocation != null) {
                    this.sublocationService.create(newSublocation, this.locationKey)
                        .then((createdSublocation: SubLocation) => {
                            this.location.sublocations.push(createdSublocation);
                            this.sublocationTable.addSublocation(createdSublocation);
                        });
                }
            });
    }

    public openImportSublocationDialog() {
        this.dialog.open(SublocationImportDialogComponent, this.getDialogConfig())
            .afterClosed()
            .subscribe((response: any) => {
                if (response != null) {
                    this.sublocationImporterService.import(response.file, this.locationKey)
                        .then((createdSublocation: SubLocation) => {
                            this.documentService.create(this.customer._id, response.file.files[0],
                                `Upload file for '${this.location.name}' location's '${createdSublocation.name}' sub-location`)
                                .then(() => {
                                    this.notifierService.success(`Sub-location '${createdSublocation.name}' uploaded successfully!`);
                                });

                            this.location.sublocations.push(createdSublocation);
                            this.sublocationTable.addSublocation(createdSublocation);
                        });
                }
            });
    }

    public openCreateForkliftDialog() {
        this.dialog.open(ForkliftDialogComponent, this.getDialogConfig())
            .afterClosed()
            .subscribe((newForklift: Forklift) => {
                if (newForklift != null) {
                    this.forkliftService.create(newForklift, this.locationKey)
                        .then((createdForklift: Forklift) => {
                            this.location.forklifts.push(createdForklift);
                            this.forkliftTable.addForklift(createdForklift);
                        });
                }
            });
    }

    public openCreateNoteDialog() {
        const config: MatDialogConfig = this.getDialogConfig();
        config.width = NoteDialogComponent.defaultWidth;
        this.dialog.open(NoteDialogComponent, config)
            .afterClosed()
            .subscribe((newNote: Note) => {
                if (newNote != null) {
                    newNote.customerId = this.customer._id;
                    newNote.locationID = this.location._id;
                    this.noteService.create(newNote)
                        .then((note: Note) => {
                            this.noteTable.addRow(note);
                        });
                }
            });
    }

    public onNotesChanged() {
        if (this.locationKey.customerId) {
            this.updateNoteCount(this.locationKey.customerId, this.locationKey.locationId);
        }
    }

    public onAddressChange(updatedAddress: Address) {
        this.location.address = updatedAddress;
        this.customerService.update(this.customer._id, this.customer)
            .then((updatedCustomer: Customer) => {
                this.customer = updatedCustomer;
            });
    }

    public back() {
        this.router.navigate(this.customerLink);
    }
}
