import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardAnonymous implements CanActivate {

    constructor(public auth: AuthService, private router: Router) {}

    public canActivate() {
        if (this.auth.isLoggedIn) {
            this.router.navigate(['/customers']);
        }
        return !this.auth.isLoggedIn;
    }
}
