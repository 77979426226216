import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor() { }

    public download(response: HttpResponse<Blob>) {
    // let blob = new Blob([response.blob()], { type: response.headers.get('content-type') });
        const fileName = this.getFileName(response);
        saveAs(response.body, fileName);
    }

    private getFileName(response: HttpResponse<any>) {
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        return result.replace(/"/g, '');
    }
}
