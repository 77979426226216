export class Column<T> {
    public get isVisibleByDefault(): boolean {
        return this._isVisibleByDefault;
    }

    private _isVisible: boolean = this.isVisibleByDefault;
    public get isVisible(): boolean {
        return this._isVisible;
    }
    public set isVisible(v: boolean) {
        this._isVisible = v;
    }

    constructor(public id: keyof T,
        public label: string,
        private _isVisibleByDefault: boolean,
        protected formatValue: (v: any) => any = null) {
    }

    public getValue(row: T): any {
        const columnValue: any = row[this.id];
        return this.formatValue == null
            ? columnValue
            : this.formatValue(columnValue);
    }

    public getTotal(_dataSource: any): any {
        return '';
    }
}

export class ActionColumn<T> extends Column<T> {
    constructor(public id: keyof T,
        public label: string,
        isVisible: boolean,
        public action: (item: T) => void,
        protected formatValue: (v: any) => any = null) {
        super(id, label, isVisible);
    }
}

export class NumericColumn<T> extends Column<T> {
    constructor(public id: keyof T,
        public label: string,
        isVisible: boolean,
        private roundValue: boolean = true,
        private units: string = null) {
        super(id, label, isVisible);
        this.formatValue = this.formatNumericValue;
    }

    public getValue(row: T): any {
        const columnValue: number = <any>row[this.id];
        return this.formatValue(columnValue);
    }

    public getTotal(dataSource: any): any {
        const total: number = dataSource.data
            .map(row => row[this.id])
            .reduce((t, v) => t + v, 0);
        return this.formatNumericValue(Math.round(total));
    }

    private formatNumericValue(value: any): any {
        value = this.roundValue ? Math.round(value) : value;
        if (value == null) {
            return '';
        }
        const formattedValue: string = value.toLocaleString();
        return this.units == null
            ? formattedValue
            : `${formattedValue} ${this.units}`;
    }
}
