import { Component, Input } from '@angular/core';
import { Button } from '../models/button';

@Component({
    selector: 'bet-fab-speed-dial',
    templateUrl: './fab-speed-dial.component.html',
    styleUrls: ['./fab-speed-dial.component.scss'],
    host: {
        "(mouseleave)": 'onMouseLeave()',
        "[class.active]": 'isExpanded'
    }
})
export class FabSpeedDialComponent {
    @Input() primaryButton: Button;
    @Input() secondaryButtons: Button[];

    public isExpanded = false;

    public onMouseEnter() {
        this.isExpanded = true;
    }

    public onMouseLeave() {
        this.isExpanded = false;
    }

    public onTouchEnd(event: any) {
        if (!this.isExpanded) {
            this.isExpanded = true;
            event.preventDefault();
        }
    }

    public onClickPrimary() {
        this.primaryButton.action();
        this.isExpanded = false;
    }

    public onBlurPrimary() {
        this.isExpanded = false;
    }
}
