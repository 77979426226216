import { Component, OnInit, Input } from '@angular/core';
import { TableComponentBase } from '../../shared/components/table.component';
import { Charger } from '../models/charger';
import { Column, NumericColumn } from '../../shared/models/column';
import { RowAction } from '../../shared/models/row-action';
import { DateFormatter } from '../../core/services/date.service';
import { SubLocation } from '../models/sublocation';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChargerAuditDialogComponent } from './charger-audit-dialog.component';
import { AuditService } from '../services/audit.service';
import { Audit } from '../models/audit';
import { Router } from '@angular/router';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { ChargerService } from '../services/charger.service';
import { SublocationKey } from '../models/sublocation-key';
import { ChargerDialogComponent } from './charger-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { AuditDialogResponse } from '../models/audit-dialog-response';
import { ChargerDialogData } from '../models/charger-dialog-data';

@Component({
    selector: 'bet-charger-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class ChargerTableComponent extends TableComponentBase<Charger> {
    private _sublocation: SubLocation;

    @Input()
    public get sublocationKey(): SublocationKey {
        return this.prefixKey;
    }
    public set sublocationKey(s: SublocationKey) {
        this.prefixKey = s;
    }
    @Input()
    public get sublocation(): SubLocation {
        return this._sublocation;
    }
    public set sublocation(s: SubLocation) {
        this._sublocation = s;
        this.loadChargers();
    }

    public title = 'Chargers';

    protected tableId = 'charger-table';
    protected editDialogComponentRef = ChargerDialogComponent;
    protected prefixKey: SublocationKey;
    protected get detailCollection(): Charger[] {
        return this.sublocation.chargers;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Chargers';
    }
    public columns: Column<Charger>[] = [
        new Column<Charger>('_id', 'ID', false),
        new Column<Charger>('modelNumber', 'Model Number', true),
        new Column<Charger>('serialNumber', 'Serial Number', true),
        new Column<Charger>('type', 'Type', true),
        new NumericColumn<Charger>('numberOfAudits', 'No. of Audits', true, true),
        new Column<Charger>('lastAuditDate', 'Last Audit Date', true, DateFormatter.getLocaleDateString),
        new NumericColumn<Charger>('currentEnergyUsageKwh', 'Quarterly Energy Usage (kWh)', true, false, 'kWh'),
        new Column<Charger>('effectiveDate', 'Effective Date', false, DateFormatter.getLocaleDateString),
        new Column<Charger>('expireDate', 'Expire Date', false, DateFormatter.getLocaleDateString),
    ];
    public rowActions: RowAction<Charger>[] = [
        new RowAction<Charger>('delete', 'Delete Charger', this.delete.bind(this)),
        new RowAction<Charger>('edit', 'Edit Charger', this.openEditDialog.bind(this)),
        new RowAction<Charger>('trending_up', 'Record Audit', this.openAuditDialog.bind(this)),
        new RowAction<Charger>('launch', 'Charger Details', this.redirectToChargerDetails.bind(this))
    ];

    constructor(private auditService: AuditService,
        chargerService: ChargerService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        private router: Router,
        dialog: MatDialog) {
        super(chargerService, authService, userService, confirmationService, dialog);
    }

    protected getEditData(row: Charger): ChargerDialogData {
        return new ChargerDialogData(row, row.getEnergyUsageKwh());
    }

    protected getRowFromResponse(response: ChargerDialogData): Charger {
        return response.charger;
    }

    protected getDeleteConfirmationMessage(row: Charger): string {
        return `Are you sure you want to delete charger '${row.serialNumber}'?`;
    }

    private loadChargers() {
        const chargers: Charger[] = this.sublocation == null || this.sublocation.chargers == null
            ? []
            : this.sublocation.chargers;
        this.setRows(chargers);
    }

    public addCharger(newCharger: Charger) {
        this.addRow(newCharger);
    }

    public openAuditDialog(row: Charger) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            chargers: this.sublocation.chargers,
            chargerId: row._id
        };
        this.dialog.open(ChargerAuditDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response: AuditDialogResponse) => {
                if (response != null) {
                    const keyPrefixes: any = {
                        customerId: this.sublocationKey.customerId,
                        locationId: this.sublocationKey.locationId,
                        sublocationId: this.sublocationKey.sublocationId,
                        chargerId: row._id
                    };
                    response.audit.type = 'Audit';
                    this.auditService.create(response.audit, keyPrefixes)
                        .then((createdAudit: Audit) => {
                            row.audits.push(createdAudit);
                        });
                }
            });
    }

    public onRowDblClick(row: Charger) {
        this.redirectToChargerDetails(row);
    }

    private redirectToChargerDetails(charger: Charger) {
        this.router.navigate(['/customers', this.sublocationKey.customerId,
            "locations", this.sublocationKey.locationId,
            "sublocations", this.sublocationKey.sublocationId,
            "chargers", charger._id]);
    }
}
