<h2 mat-dialog-title *ngIf="confirmation && confirmation.title">
  {{ confirmation?.title }}
</h2>

<mat-dialog-content>
  {{ confirmation?.message }}
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="accept()">{{ confirmation?.acceptLabel }}</button>
  <button mat-button (click)="reject()">{{ confirmation?.rejectLabel }}</button>
</mat-dialog-actions>