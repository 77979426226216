import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ReactiveFormsModule } from "@angular/forms";
import { NgxWigModule } from 'ngx-wig';
import { CookieModule } from 'ngx-cookie';
import { NotificationComponent } from './components/notification.component';
import { NotificationDialogComponent } from './components/notification-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog.component';
import { ConfirmationComponent } from './components/confirmation.component';
import { FabSpeedDialComponent } from './components/fab-speed-dial.component';
import { ColumnPickerDialogComponent } from './components/column-picker-dialog.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatMomentDateModule,
        ReactiveFormsModule,
        NgxWigModule,
        CookieModule.forChild()
    ],
    declarations: [
        NotificationComponent,
        NotificationDialogComponent,
        ConfirmationComponent,
        ConfirmationDialogComponent,
        FabSpeedDialComponent,
        ColumnPickerDialogComponent,
        ClickOutsideDirective
    ],
    exports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatMomentDateModule,
        ReactiveFormsModule,
        NgxWigModule,
        CookieModule,
        NotificationComponent,
        NotificationDialogComponent,
        ConfirmationComponent,
        ConfirmationDialogComponent,
        FabSpeedDialComponent,
    ]
})
export class SharedModule { }
