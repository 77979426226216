import * as moment from 'moment/moment';

export class DateFormatter {

    public static getLocaleDateString(date: Date): string {
        if (!DateFormatter.isValidDate(date)) {
            return '';
        }
        return date.toLocaleDateString(DateFormatter.getLang());
    }

    public static getQuarterDateString(date: Date): string {
        if (!DateFormatter.isValidDate(date)) {
            return '';
        }
        return `Q${moment(date).format('Q Y')}`;
    }

    private static isValidDate(date: Date) {
        return date != null && date instanceof Date && !isNaN(date.getTime());
    }

    private static getLang() {
        let language: string;
        if (navigator.languages != undefined)  {
            language = navigator.languages[0];
        } else {
            language = navigator.language;
        }
        return language == null ? 'en-US' : language;
    }
}
