<mat-card>
  <mat-card-title i18n>
    Reset Password
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-form-field>
        <input matInput placeholder="Password" i18n-placeholder
                formControlName="password" type="password" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Confirm Password" i18n-placeholder
                formControlName="confirmPassword" type="password" />
      </mat-form-field>
      <button mat-raised-button color="primary" [disabled]="!form.valid" i18n>
        Reset Password
      </button>
    </form>
  </mat-card-content>
</mat-card>
