export class State {
    constructor(public name: string,
        public abbreviation: string) {

    }

    public static convertToModel(model: any): State {
        if (model == null) {
            return null;
        }
        return new State(model.name, model.abbreviation);
    }
}
