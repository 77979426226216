import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { LoginResponse } from '../models/login-response';
import { map } from 'rxjs/operators';
import { ApiHelper } from './api-helper';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService<User> {
    protected slug = 'users';

    constructor(http: HttpClient) {
        super(http);
    }

    protected convertToModel(model: any): User {
        return User.convertToModel(model);
    }

    public login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post("/api/login", { email: email, password: password })
            .pipe(map(LoginResponse.convertToModel));
    }

    public auth() {
        return this.http.post("/api/auth", null)
            .toPromise()
            .then(this.convertToModel)
            .catch(ApiHelper.handleError);
    }
}
