<ng-container *ngIf="isLoggedIn">
  <button mat-flat-button color="accent" [matMenuTriggerFor]="menu">
      <span class="welcome-message">{{greeting}}</span>
      <img [src]="gravatarUrl" />
  </button>
  <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="profile()">
        <mat-icon>account_circle</mat-icon>
        <span i18n>My Profile</span>
      </button>
      <button mat-menu-item (click)="settings()">
        <mat-icon>settings</mat-icon>
        <span i18n>Settings</span>
      </button>
      <button mat-menu-item (click)="logOut()">
        <mat-icon>power_settings_new</mat-icon>
        <span i18n>Log Out</span>
      </button>
    </mat-menu>
</ng-container>