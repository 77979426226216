<h2 mat-dialog-title i18n>Customer</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Company Name" i18n-placeholder
               formControlName="companyName" />
    </mat-form-field>
    <mat-form-field class="customer-id">
        <input matInput placeholder="Customer ID" i18n-placeholder
                formControlName="customerId" />
        <mat-hint i18n>
            Used to generate the serial number for reporting
        </mat-hint>
    </mat-form-field>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Start Date"
                        formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>