<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  <ol>
    <li *ngFor="let message of messages">
      {{message}}
    </li>
  </ol>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="dismiss()" i18n>Dismiss</button>
</mat-dialog-actions>