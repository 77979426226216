<div class="profile-overview">
  <img [src]="gravatarUrl" alt="Profile Image" i18n-alt />
  <div class="profile-summary">
    <h2 i18n>My Profile</h2>
    <div class="account-info">
      <span class="label" i18n>Name:</span>
      <span class="value">{{ (currentUser | async)?.name.getFormattedName() }}</span>
    </div>
    <div class="account-info">
      <span class="label" i18n>Email:</span>
      <span class="value">{{ (currentUser | async)?.email }}</span>
    </div>
    <div class="account-info">
      <span class="label" i18n>Password:</span>
      <span class="value">********</span>
    </div>
    <button mat-button type="button" i18n (click)="openEditDialog()">
      Edit
    </button>
  </div>
</div>

<bet-user-table></bet-user-table>

<button mat-fab color="primary" class="md-fab-bottom-right"
        (click)="openCreateDialog()"
        matTooltip="Create User" i18n-matTooltip
        matTooltipPosition="left">
    <mat-icon class="default-icon">add</mat-icon>
</button>
