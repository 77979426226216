import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { Confirmation } from '../../core/models/confirmation';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Component({
    selector: 'bet-confirmation',
    template: '',
})
export class ConfirmationComponent implements OnInit {
    private confirmationRequestedSubscription: Subscription;

    constructor(private dialog: MatDialog,
        private confirmationService: ConfirmationService) {

    }

    public ngOnInit() {
        this.confirmationRequestedSubscription = this.confirmationService.confirmationRequested
            .subscribe(() => {

                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.width = '350px';

                this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            });
    }

    public ngOnDestroy() {
        if (this.confirmationRequestedSubscription) {
            this.confirmationRequestedSubscription.unsubscribe();
        }
    }
}
