import { Component, Input, OnInit } from '@angular/core';
import { TableComponentBase } from '../../shared/components/table.component';
import { Forklift } from '../models/forklift';
import { Column } from '../../shared/models/column';
import { RowAction } from '../../shared/models/row-action';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForkliftService } from '../services/forklift.service';
import { LocationKey } from '../models/location-key';
import { Location } from '../models/location';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { ForkliftDialogComponent } from './forklift-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'bet-forklift-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class ForkliftTableComponent extends TableComponentBase<Forklift> {

    protected prefixKey: LocationKey;
    private _location: Location;

    @Input()
    public get locationKey(): LocationKey {
        return this.prefixKey;
    }
    public set locationKey(k: LocationKey) {
        this.prefixKey = k;
    }

    @Input()
    public get location(): Location {
        return this._location;
    }
    public set location(location: Location) {
        if (location != null && location.forklifts != null &&
      location.forklifts.length > 0 && (this.location == null || location.forklifts !== this.location.forklifts)) {
            this._location = location;
            this.setRows(location.forklifts);
        }
    }

    protected tableId = 'forklift-table';
    protected editDialogComponentRef = ForkliftDialogComponent;
    protected get detailCollection(): Forklift[] {
        return this.location == null ? [] : this.location.forklifts;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Forklift';
    }

    public columns: Column<Forklift>[] = [
        new Column<Forklift>('_id', 'ID', false),
        new Column<Forklift>('make', 'Make', true),
        new Column<Forklift>('model', 'Model', true),
        new Column<Forklift>('modelYear', 'Model Year', true),
        new Column<Forklift>('serialNumber', 'Serial Number', true),
        new Column<Forklift>('fuelType', 'Fuel Type', true, (fuelType: string) => {
            switch (fuelType) {
            case 'D': return 'Diesel';
            case 'E': return 'Electric';
            default: return 'Unknown';
            }
        }),
    ];
    public rowActions: RowAction<Forklift>[] = [
        new RowAction<Forklift>('delete', 'Delete Forklift', this.delete.bind(this)),
        new RowAction<Forklift>('edit', 'Edit Forklift', this.openEditDialog.bind(this))
    ];

    constructor(forkliftService: ForkliftService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(forkliftService, authService, userService, confirmationService, dialog);
    }

    protected getDeleteConfirmationMessage(row: Forklift): string {
        return `Are you sure you want to delete the forklift with serial number ${row.serialNumber}?`;
    }

    public addForklift(newForklift: Forklift) {
        this.addRow(newForklift);
    }
}
