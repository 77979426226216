import { Audit } from "./audit";
import { IModel } from "./IModel";
import * as moment from 'moment/moment';

export class Charger implements IModel {
    private _expireQuarterEnd: Date;
    private get expireQuarterEnd(): Date {
        if (this._expireQuarterEnd == null && this.expireDate != null) {
            this._expireQuarterEnd = moment(this.expireDate)
                .endOf('quarter')
                .toDate();
        }
        return this._expireQuarterEnd;
    }

    private _effectiveQuarterStart: Date;
    private get effectiveQuarterStart(): Date {
        if (this._effectiveQuarterStart == null && this.effectiveDate != null) {
            this._effectiveQuarterStart = moment(this.effectiveDate)
                .endOf('quarter')
                .toDate();
        }
        return this._effectiveQuarterStart;
    }

    public get numberOfAudits(): number {
        return this.audits == null ? 0 : this.audits.length;
    }

    public get lastAuditDate(): Date {
        return this.audits == null
            ? null
            : new Date(Math.max.apply(null, this.audits.map(a => a.auditDate)));
    }

    public get currentEnergyUsageKwh(): number {
        return this.getEnergyUsageKwh();
    }

    constructor(public _id: string,
        public modelNumber: string,
        public serialNumber: string,
        public type: string,
        public audits: Audit[],
        public effectiveDate: Date,
        public expireDate: Date,
        public updateDate?: Date,
        public createDate?: Date) {
    }

    public static convertToModel(model: any): Charger {
        if (model == null) {
            return null;
        }
        return new Charger(model._id,
            model.modelNumber,
            model.serialNumber,
            model.type,
            model.audits == null ? null : model.audits.map(Audit.convertToModel),
            new Date(model.effectiveDate),
            model.expireDate ? new Date(model.expireDate) : null,
            new Date(model.updatedAt),
            new Date(model.createdAt));
    }

    public static createEmpty(): Charger {
        return new Charger(
            null,
            "",
            "",
            "",
            null,
            null,
            null,
            null,
            null
        );
    }

    public getEnergyUsageKwh(date?: moment.Moment): number {
        const asOfDate: Date = date ? date.toDate() : new Date();
        const isChargerEffective: boolean = date == null || this.effectiveQuarterStart < asOfDate;
        const isChargerExpired: boolean = this.expireQuarterEnd < asOfDate;
        if (!isChargerEffective || isChargerExpired) {
            return null;
        }

        const effectiveAudits: Audit[] = date == null || this.audits == null
            ? this.audits
            : this.audits.filter(a => a.auditDate <= asOfDate);
        if (effectiveAudits == null || effectiveAudits.length === 0) {
            return null;
        }
        const energyUsage: number = Math.min(...effectiveAudits.map(a => a.energyUsageKwh));
        return energyUsage;
    }
}
