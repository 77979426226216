import { Component, OnInit, Input, Inject } from '@angular/core';
import { Column } from '../models/column';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-column-picker-dialog',
    templateUrl: './column-picker-dialog.component.html',
    styleUrls: ['./column-picker-dialog.component.scss']
})
export class ColumnPickerDialogComponent implements OnInit {
    public form: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<ColumnPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public columns: Column<any>[],
        private formBuilder: UntypedFormBuilder) {

    }

    public ngOnInit() {
        this.form = this.formBuilder.group({ });

        for (const column of this.columns) {
            this.form.addControl(<string>column.id, new UntypedFormControl(column.isVisible));
        }
    }

    public save() {
        for (const column of this.columns) {
            column.isVisible = this.form.get(<string>column.id).value;
        }
        this.dialogRef.close({ columns: this.columns });
    }

    public cancel() {
        this.dialogRef.close();
    }

    public reset() {
        this.dialogRef.close({ reset: true });
    }
}
