<h2 mat-dialog-title>LCFS Quarterly Report</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let s of states" [value]="s.abbreviation">
                {{s.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Year"  i18n-placeholder
                    formControlName="year">
            <mat-option *ngFor="let y of years" [value]="y">
                {{y}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Quarter"  i18n-placeholder
                    formControlName="quarter">
            <mat-option *ngFor="let q of quarters" [value]="q">
                {{q}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid">
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>