import { Component, OnInit, Input } from '@angular/core';
import { TableComponentBase } from '../../shared/components/table.component';
import { Location } from '../models/location';
import { SubLocation } from '../models/sublocation';
import { Column, NumericColumn } from '../../shared/models/column';
import { Router } from '@angular/router';
import { RowAction } from '../../shared/models/row-action';
import { MatDialog } from '@angular/material/dialog';
import { LocationKey } from '../models/location-key';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { SublocationService } from '../services/sublocation.service';
import { SublocationDialogComponent } from './sublocation-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'bet-sublocation-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class SublocationTableComponent extends TableComponentBase<SubLocation> implements OnInit {
    private _location: Location;

    @Input()
    public get locationKey(): LocationKey {
        return this.prefixKey;
    }
    public set locationKey(k: LocationKey) {
        this.prefixKey = k;
    }
    @Input()
    public get location(): Location {
        return this._location;
    }
    public set location(c: Location) {
        this._location = c;
        this.loadSublocations();
    }

    protected tableId = 'sublocation-table';
    protected editDialogComponentRef = SublocationDialogComponent;
    protected prefixKey: LocationKey;
    protected get detailCollection(): SubLocation[] {
        return this.location.sublocations;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Sub-Location';
    }
    public columns: Column<SubLocation>[] = [
        new Column<SubLocation>('_id', 'ID', false),
        new Column<SubLocation>('name', 'Sub-Location Name', true),
        new NumericColumn<SubLocation>('numberOfChargers', 'No. of Chargers', true, true),
        new NumericColumn<SubLocation>('quarterlyEnergyUsageKwh', 'Quarterly Energy Usage (kWh)', true, true, 'kWh')
    ];
    public rowActions: RowAction<SubLocation>[] = [
        new RowAction<SubLocation>('delete', 'Delete Sub-Location', this.delete.bind(this)),
        new RowAction<SubLocation>('edit', 'Edit Sub-Location', this.openEditDialog.bind(this)),
        new RowAction<SubLocation>('launch', 'Location Details', this.redirectToSublocationDetails.bind(this))
    ];

    constructor(private router: Router,
        sublocationService: SublocationService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(sublocationService, authService, userService, confirmationService, dialog);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.loadSublocations();
    }

    protected onRowDblClick(row: SubLocation) {
        this.redirectToSublocationDetails(row);
    }

    private loadSublocations() {
        const sublocations: SubLocation[] = this.location == null || this.location.sublocations == null
            ? []
            : this.location.sublocations;
        this.setRows(sublocations);
    }

    public refresh() {
        this.loadSublocations;
    }

    public addSublocation(newSublocation: SubLocation) {
        this.addRow(newSublocation);
    }

    private redirectToSublocationDetails(sublocation: SubLocation) {
        this.router.navigate(['/customers', this.locationKey.customerId,
            "locations", this.locationKey.locationId,
            "sublocations", sublocation._id]);
    }

    protected getDeleteConfirmationMessage(row: SubLocation): string {
        return `Are you sure you want to delete sub-location '${row.name}'?`;
    }
}
