import { Injectable } from '@angular/core';
import { Confirmation } from '../models/confirmation';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {

    private _confirmation: Confirmation;
    public get confirmation(): Confirmation {
        return this._confirmation;
    }

    private _confirmationRequested: Subject<Confirmation> = new Subject<Confirmation>();
    public get confirmationRequested(): Observable<Confirmation> {
        return this._confirmationRequested.asObservable();
    }

    public confirm(confirmation: Confirmation) {
        this._confirmation = confirmation;
        this._confirmationRequested.next(confirmation);
    }
}
