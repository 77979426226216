import { HttpClient } from '@angular/common/http';
import { IModel } from '../../customers/models/IModel';
import { ApiHelper } from './api-helper';

export abstract class BaseService<TModel extends IModel> {
    protected abstract slug;

    constructor(protected http: HttpClient) { }

    protected getRootUrl(_keyPrefixes: any): string {
        return `/api/${this.slug}`;
    }

    protected abstract convertToModel(model: any): TModel;

    public getAll(keyPrefixes?: any): Promise<void | TModel[]> {
        return this.http.get(this.getRootUrl(keyPrefixes))
            .toPromise()
            .then((response: any[]) => response.map(v => this.convertToModel(v)))
            .catch(ApiHelper.handleError);
    }

    public count(keyPrefixes?: any): Promise<void | number> {
        return this.http.get(`${this.getRootUrl(keyPrefixes)}/count`)
            .toPromise()
            .then(response => response as number)
            .catch(ApiHelper.handleError);
    }

    public get(id: string, keyPrefixes?: any): Promise<void | TModel> {
        return this.http.get(`${this.getRootUrl(keyPrefixes)}/${id}`)
            .toPromise()
            .then(this.convertToModel)
            .catch(ApiHelper.handleError);
    }

    public update(id: string, updatedValue: TModel, keyPrefixes?: any): Promise<void | TModel> {
        return this.http.put(`${this.getRootUrl(keyPrefixes)}/${id}`, updatedValue)
            .toPromise()
            .then(this.convertToModel)
            .catch(ApiHelper.handleError);
    }

    public create(newValue: TModel, keyPrefixes?: any): Promise<void | TModel> {
        return this.http.post(this.getRootUrl(keyPrefixes), newValue)
            .toPromise()
            .then(this.convertToModel)
            .catch(ApiHelper.handleError);
    }

    public delete(id: string, keyPrefixes?: any): Promise<void | Object> {
        return this.http.delete(`${this.getRootUrl(keyPrefixes)}/${id}`,
            { responseType: 'text', observe: 'response' })
            .toPromise()
            .catch(ApiHelper.handleError);
    }
}
