import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification } from '../models/notification'

@Injectable({
    providedIn: 'root'
})
export class NotifierService {
    private _notifications: Notification[] = [];
    public get notifications(): Notification[]  {
        return this._notifications;
    }

    private _noificationAdded: Subject<Notification[]> = new Subject<Notification[]>();
    public get messageAdded(): Observable<Notification[]> {
        return this._noificationAdded.asObservable();
    }

    public success(message: string) {
        this.add(new Notification('Success', message));
    }

    public error(message: string) {
        this.add(new Notification('Error', message));
    }

    private add(notification: Notification) {
        if (!this.notifications.some(m => m === notification)) {
            this._notifications.push(notification);
            this._noificationAdded.next(this.notifications);
        }
    }

    public clear() {
        this._notifications = [];
    }
}
