import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerOverlayService } from './core/services/spinner-overlay.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title = 'Beyond Energy';

    public get showSpinner(): boolean {
        return this.spinnerService.isVisible;
    }

    constructor(private spinnerService: SpinnerOverlayService,
        private router: Router) {

    }

    public home() {
        this.router.navigate(['/']);
    }
}
