import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Audit } from '../models/audit';
import { BaseService } from '../../core/services/base.service';
import { ChargerKey } from '../models/charger-key';

@Injectable({
    providedIn: 'root'
})
export class AuditService extends BaseService<Audit> {
    protected slug = 'audits';

    constructor(http: HttpClient) {
        super(http);
    }

    protected getRootUrl(keyPrefixes: ChargerKey): string {
        const customerId: string = keyPrefixes.customerId;
        const locationId: string = keyPrefixes.locationId;
        const sublocationId: string = keyPrefixes.sublocationId;
        const chargerId: string = keyPrefixes.chargerId;
        return `/api/customers/${customerId}/locations/${locationId}/sublocations/${sublocationId}/chargers/${chargerId}/audits`;
    }

    protected convertToModel(model: any): Audit {
        return Audit.convertToModel(model);
    }
}
