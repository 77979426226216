import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { AddressComponent } from './components/address.component';
import { CustomerTableComponent } from './components/customer-table.component';
import { CustomerDialogComponent } from './components/customer-dialog.component';
import { CustomerOverviewComponent } from './components/customer-overview.component';
import { CustomerDetailComponent } from './components/customer-detail.component';
import { LocationTableComponent } from './components/location-table.component';
import { LocationDialogComponent } from './components/location-dialog.component';
import { LocationDetailComponent } from './components/location-detail.component';
import { SublocationImportDialogComponent } from './components/sublocation-import-dialog.component';
import { ChargerTableComponent } from './components/charger-table.component';
import { SublocationDetailComponent } from './components/sublocation-detail.component';
import { SublocationTableComponent } from './components/sublocation-table.component';
import { ChargerDialogComponent } from './components/charger-dialog.component';
import { SublocationDialogComponent } from './components/sublocation-dialog.component';
import { AddressDialogComponent } from './components/address-dialog.component';
import { ForkliftTableComponent } from './components/forklift-table.component';
import { ForkliftDialogComponent } from './components/forklift-dialog.component';
import { DocumentUploadDialogComponent } from './components/document-upload-dialog.component';
import { DocumentTableComponent } from './components/document-table.component';
import { NoteTableComponent } from './components/note-table.component';
import { NoteDialogComponent } from './components/note-dialog.component';
import { ChargerAuditDialogComponent } from './components/charger-audit-dialog.component';
import { ChargerDetailComponent } from './components/charger-detail.component';
import { ChargerAuditTableComponent } from './components/charger-audit-table.component';
import { ChargerCountCardComponent } from './components/charger-count-card.component';
import { ChargerEnergyUsageCardComponent } from './components/charger-energy-usage-card.component';
import { CustomerAgeCardComponent } from './components/customer-age-card.component';
import { ReportWidgetComponent } from './components/report-widget.component';
import { FleetManagerContactDialogComponent } from './components/fleet-manager-contact-dialog.component';
import { LcfsReportDialogComponent } from './components/lcfs-report-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialFileInputModule
    ],
    declarations: [
        AddressComponent,
        CustomerTableComponent,
        CustomerDialogComponent,
        CustomerOverviewComponent,
        CustomerDetailComponent,
        LocationTableComponent,
        LocationDialogComponent,
        LocationDetailComponent,
        SublocationImportDialogComponent,
        ChargerTableComponent,
        SublocationDetailComponent,
        SublocationTableComponent,
        ChargerDialogComponent,
        SublocationDialogComponent,
        AddressDialogComponent,
        ForkliftTableComponent,
        ForkliftDialogComponent,
        DocumentUploadDialogComponent,
        DocumentTableComponent,
        NoteTableComponent,
        NoteDialogComponent,
        ChargerAuditDialogComponent,
        ChargerDetailComponent,
        ChargerAuditTableComponent,
        ChargerCountCardComponent,
        ChargerEnergyUsageCardComponent,
        CustomerAgeCardComponent,
        ReportWidgetComponent,
        FleetManagerContactDialogComponent,
        LcfsReportDialogComponent
    ],
    exports: [
        CustomerOverviewComponent,
        ReportWidgetComponent
    ],
    providers: []
})
export class CustomersModule { }
