import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { State } from '../models/state';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    constructor(private http: HttpClient) {

    }

    public get(): Promise<State[]> {
        return this.http.get('../data/states.json')
            .toPromise()
            .then((states: any[]) => states.map(s => State.convertToModel(s)));
    }
}
