import { Customer } from './customer';
import { IChargerParent } from './IChargerParent';
import * as moment from 'moment/moment';

export class Customers implements IChargerParent {
    public get numberOfForklifts(): number {
        return this.customers
            .map(c => c.numberOfForklifts)
            .reduce((t, v) => t + v, 0);
    }

    public get numberOfChargers(): number {
        return this.customers
            .map(c => c.numberOfChargers)
            .reduce((t, v) => t + v, 0);
    }

    public get quarterlyEnergyUsageKwh(): number {
        return this.getEnergyUsageKwh();
    }

    constructor(public customers: Customer[]) {

    }

    public getEnergyUsageKwh(chargerTypeFilter?: string, date?: moment.Moment): number {
        return this.customers
            .map(loc => loc.getEnergyUsageKwh(chargerTypeFilter, date))
            .reduce((t, v) => t + v, 0);
    }
}
