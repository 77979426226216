<h2 mat-dialog-title i18n>Fleet Manager Contact</h2>

<mat-dialog-content *ngIf="location">
    <div class="name">
        <i class="material-icons">place</i>
        {{ name }}
    </div>
    <ng-container *ngIf="location.address">
        <span class="street-line-1">{{ location.address.streetAddress }}</span>
        <span class="street-line-2">{{ location.address.streetAddress2 }}</span>
        <span class="city">{{ location.address.city }}</span>
        <span class="state">{{ location.address.state }}</span>
        <span class="postal">{{ location.address.postalCode }}</span>
        <span class="country" i18n>{{ location.address.country }}</span>
    </ng-container>
    <div class="email" *ngIf="location.fleetManager.email">
        <i class="material-icons">email</i>
        <a href="mailto:{{ location.fleetManager.email }}" target="_blank">
            {{ location.fleetManager.email }}
        </a>
    </div>
    <div class="phone" *ngIf="location.fleetManager.phoneNumber">
        <i class="material-icons">phone_iphone</i>
        <a href="tel:{{ location.fleetManager.phoneNumber }}" target="_blank">
            {{ location.fleetManager.phoneNumber }}
        </a>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dismiss()" i18n>Dismiss</button>
</mat-dialog-actions>