export class Address {

    constructor(public streetAddress: string,
        public streetAddress2: string,
        public city: string,
        public state: string,
        public postalCode: string,
        public country: string) {

    }

    public static convertToModel(model: any): Address {
        if (model == null) {
            return null;
        }
        return new Address(model.streetAddress,
            model.streetAddress2,
            model.city,
            model.state,
            model.postalCode,
            model.country);
    }

    public static createEmpty(): Address {
        return new Address('', '', '', '', '', '');
    }
}
