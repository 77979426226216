import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../core/services/base.service';
import { LocationKey } from '../models/location-key';
import { Forklift } from '../models/forklift';

@Injectable({
    providedIn: 'root'
})
export class ForkliftService extends BaseService<Forklift> {
    protected slug = 'forklifts';

    constructor(http: HttpClient) {
        super(http);
    }

    protected getRootUrl(keyPrefixes: LocationKey): string {
        const customerId: string = keyPrefixes.customerId;
        const locationId: string = keyPrefixes.locationId;
        return `/api/customers/${customerId}/locations/${locationId}/forklifts`;
    }

    protected convertToModel(model: any): Forklift {
        return Forklift.convertToModel(model);
    }
}
