import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent,
    HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, never } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';
import { LoginService } from './login.service';
import { NotifierService } from './notifier.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private authTokenService: AuthTokenService,
        private loginService: LoginService,
        private notifierService: NotifierService) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        const token: string = this.authTokenService.get();

        return next.handle(this.addTokenToRequest(request, token))
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status == 401) {
                            this.notifierService.error('Unable to authenticate your request. Your session may have expired. Please try logging in again.');
                            this.loginService.logout();
                        } else {
                            let message: string = err.message;
                            if (err.error != null && err.error.message) {
                                message = err.error.message;
                            }
                            this.notifierService.error(message);
                        }
                    }
                    return throwError(err);
                }));
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token == null) {
            return request;
        }

        return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
    }
}
