import { Name } from "../../core/models/name";

export class FleetManager extends Name {
    constructor(firstName: string,
        lastName: string,
        public phoneNumber: string,
        public email: string) {
        super(firstName, lastName);
    }

    public static convertToModel(model: any): FleetManager {
        if (model == null) {
            return null;
        }
        return new FleetManager(model.firstName, model.lastName,
            model.phoneNumber, model.email);
    }

    public static createEmpty(): FleetManager {
        return new FleetManager('', '', '', '');
    }
}
