import { IModel } from "./IModel";

export class Forklift implements IModel {
    constructor(public _id: string,
        public make: string,
        public model: string,
        public modelYear: number,
        public serialNumber: string,
        public fuelType: string,
        public updateDate: Date,
        public createDate: Date) {

    }

    public static convertToModel(model: any): Forklift {
        if (model == null) {
            return null;
        }
        return new Forklift(model._id,
            model.make,
            model.model,
            model.modelYear,
            model.serialNumber,
            model.fuelType,
            new Date(model.updatedAt),
            new Date(model.createdAt));
    }

    public static createEmpty(): Forklift {
        return new Forklift(
            null,
            "",
            "",
            0,
            "",
            "",
            null,
            null
        );
    }
}
