<h2 mat-dialog-title i18n>Charger</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field formGroupName="charger">
        <input matInput placeholder="Model Number" i18n-placeholder
               formControlName="modelNumber" />
    </mat-form-field>
    <mat-form-field formGroupName="charger">
        <input matInput placeholder="Serial Number" i18n-placeholder
               formControlName="serialNumber" />
    </mat-form-field>
    <mat-form-field formGroupName="charger">
        <mat-select placeholder="Charger Type" formControlName="type">
            <mat-option *ngFor="let type of chargerTypes" [value]="type">
                {{ type }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Baseline Quarterly Energy Usage (kWh)" i18n-placeholder
               [readonly]="!isCreating"
               formControlName="energyUsageKwh" type="number" />
    </mat-form-field>
    <mat-form-field formGroupName="charger">
        <input matInput [matDatepicker]="effectivePicker" placeholder="Effective Date"
                        formControlName="effectiveDate">
        <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectivePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field formGroupName="charger">
        <input matInput [matDatepicker]="expirePicker" placeholder="Expire Date"
                        formControlName="expireDate">
        <mat-datepicker-toggle matSuffix [for]="expirePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirePicker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>