import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { Location } from '../models/location';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocationDialogComponent, LocationDialogData } from './location-dialog.component';
import { DocumentUploadDialogComponent } from './document-upload-dialog.component';
import { Button, Icon } from '../../shared/models/button';
import { DocumentService } from '../services/document.service';
import { DocumentTableComponent } from './document-table.component';
import { CustomerDocument } from '../models/document';
import { NoteService } from '../services/note.service';
import { Note, NoteFilter } from '../models/note';
import { NoteDialogComponent } from './note-dialog.component';
import { NoteTableComponent } from './note-table.component';

@Component({
    selector: 'bet-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./detail.component.scss', './customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
    public customer: Customer;
    public noteFilter: NoteFilter;
    public noteCount: Promise<void | number>;
    public documentCount: Promise<void | number>;

    public addLocationButton: Button = new Button(Icon.createMaterialIcon('location_on'),
        "Add Location", null, this.openCreateLocationDialog.bind(this));
    public addNoteButton: Button = new Button(Icon.createMaterialIcon('notes'),
        "Add Note", null, this.openCreateNoteDialog.bind(this));
    public uploadDocumentButton: Button = new Button(Icon.createMaterialIcon('cloud_upload'),
        "Upload Document", null, this.openUploadDialog.bind(this));

    @ViewChild(DocumentTableComponent)
    private documentTable: DocumentTableComponent;

    @ViewChild(NoteTableComponent)
    private noteTable: NoteTableComponent;

    constructor(private customerService: CustomerService,
        private noteService: NoteService,
        private documentService: DocumentService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            const customerId: string = params["id"];
            this.noteFilter = new NoteFilter(customerId);
            this.getCustomer(customerId);
            this.updateNoteCount(customerId);
            this.updateDocumentCount(customerId);
        });
    }

    private updateNoteCount(customerId: string) {
        this.noteCount = this.noteService.count(customerId);
    }

    private updateDocumentCount(customerId: string) {
        this.documentCount = this.documentService.count(customerId);
    }

    private getCustomer(customerId: string) {
        this.customerService.get(customerId).then((c: Customer) => this.customer = c);
    }

    private getDialogConfig(): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        return dialogConfig;
    }

    public openCreateLocationDialog() {
        const dialogConfig: MatDialogConfig = this.getDialogConfig();
        dialogConfig.data = new LocationDialogData(this.customer);
        this.dialog.open(LocationDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((newLocation: Location) => {
                if (newLocation != null) {
                    this.customer.locations.push(newLocation);
                    this.customerService.update(this.customer._id, this.customer)
                        .then((updatedCustomer: Customer) => this.customer = updatedCustomer);
                }
            });
    }

    public openCreateNoteDialog() {
        const config: MatDialogConfig = this.getDialogConfig();
        config.width = NoteDialogComponent.defaultWidth;
        this.dialog.open(NoteDialogComponent, config)
            .afterClosed()
            .subscribe((newNote: Note) => {
                if (newNote != null) {
                    newNote.customerId = this.customer._id;
                    this.noteService.create(newNote)
                        .then((note: Note) => {
                            this.noteTable.addRow(note);
                        });
                }
            });
    }

    public openUploadDialog() {
        this.dialog.open(DocumentUploadDialogComponent, this.getDialogConfig())
            .afterClosed()
            .subscribe((res: any) => {
                if (res != null) {
                    this.documentService.create(this.customer._id, res.file.files[0], res.description)
                        .then((doc: CustomerDocument) => this.documentTable.addRow(doc));
                }
            });
    }

    public onNotesChanged() {
        if (this.customer) {
            this.updateNoteCount(this.customer._id);
        }
    }

    public onDocumentsChanged() {
        if (this.customer) {
            this.updateDocumentCount(this.customer._id);
        }
    }

    public back() {
        this.router.navigate(['/customers']);
    }
}
