import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { finalize, filter } from 'rxjs/operators';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { NotifierService } from './notifier.service';
import { LoginResponse } from '../models/login-response';
import { AuthService } from './auth.service';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private spinnerService: SpinnerOverlayService,
        private notifierService: NotifierService) {
        this.authService.currentUser
            .pipe(filter((u: User) => u == null || u._id == null || u._id.trim().length === 0))
            .subscribe(() => this.logout());
    }

    public login(email: string, password: string) {
        this.spinnerService.showSpinner();
        console.log('logging in');
        return this.userService.login(email, password)
            .pipe(finalize(() => this.spinnerService.hideSpinner()))
            .subscribe((res: LoginResponse) => {
                console.log('logged in');
                this.authService.setToken(res.token)
                    .then(() => this.router.navigate(['/']));
            },
            (res: Response) => {
                if (res.status === 403) {
                    this.notifierService.error('The username and/or password provided was invalid. Please try again.');
                } else {
                    this.notifierService.error('An error occurred logging in. Please try again.');
                }
            },
            () => this.spinnerService.hideSpinner());
    }

    public logout() {
        if (this.authService.isLoggedIn) {
            this.authService.clearCurrentUser();
        }
        this.router.navigate(['/login']);
    }
}
