import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login.component';
import { UserWidgetComponent } from './components/user-widget.component';
import { UserProfileComponent } from './components/user-profile.component';
import { UserTableComponent } from './components/user-table.component';
import { UserDialogComponent } from './components/user-dialog.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog.component';
import { ResetPasswordComponent } from './components/reset-password.component';
import { SiteSettingsDialogComponent } from './components/site-settings-dialog.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LoginComponent,
        UserWidgetComponent,
        UserProfileComponent,
        UserTableComponent,
        UserDialogComponent,
        ForgotPasswordDialogComponent,
        ResetPasswordComponent,
        SiteSettingsDialogComponent
    ],
    exports: [
        UserWidgetComponent
    ]
})
export class UsersModule { }
