export class HeadingColumn {
    constructor(public label: string, public widthPx: number) {

    }
}

export class HeadingColumnGroup {
    constructor(public label: string,
        public span: number,
        public headingColumns: HeadingColumn[]) {

    }
}
