import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../core/services/base.service';
import { SublocationKey } from '../models/sublocation-key';
import { Charger } from '../models/charger';

@Injectable({
    providedIn: 'root'
})
export class ChargerService extends BaseService<Charger> {
    protected slug = 'chargers';

    constructor(http: HttpClient) {
        super(http);
    }

    protected getRootUrl(keyPrefixes: SublocationKey): string {
        const customerId: string = keyPrefixes.customerId;
        const locationId: string = keyPrefixes.locationId;
        const sublocationId: string = keyPrefixes.sublocationId;
        return `/api/customers/${customerId}/locations/${locationId}/sublocations/${sublocationId}/chargers`;
    }

    protected convertToModel(model: any): Charger {
        return Charger.convertToModel(model);
    }
}
