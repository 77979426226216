<mat-card>
  <mat-card-title i18n>
    Sign In
  </mat-card-title>
  <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()">
          <mat-form-field>
            <input matInput placeholder="Email" i18n-placeholder type="email"
                    formControlName="email" />
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Password" i18n-placeholder type="password"
                    formControlName="password" />
          </mat-form-field>
          <button mat-raised-button color="primary" [disabled]="!form.valid" i18n>
            Sign In
          </button>
        </form>
  </mat-card-content>
  <mat-card-footer>
    <button mat-button type="button" (click)="openResetRequestDialog()" i18n>
      Forgot Password
    </button>
  </mat-card-footer>
</mat-card>
