import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Customer } from '../models/customer';
import { Location } from '../models/location';
import { SubLocation } from '../models/sublocation';
import { ChargerDialogComponent } from './charger-dialog.component';
import { Charger } from '../models/charger';
import { Button, Icon } from '../../shared/models/button';
import { ChargerAuditDialogComponent } from './charger-audit-dialog.component';
import { AuditService } from '../services/audit.service';
import { Audit } from '../models/audit';
import { SublocationKey } from '../models/sublocation-key';
import { AuditDialogResponse } from '../models/audit-dialog-response';
import { ChargerDialogData } from '../models/charger-dialog-data';
import { ChargerService } from '../services/charger.service';
import { ChargerTableComponent } from './charger-table.component';
import { ChargerKey } from '../models/charger-key';

@Component({
    selector: 'app-sublocation-detail',
    templateUrl: './sublocation-detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class SublocationDetailComponent implements OnInit {
    public sublocationKey: SublocationKey;

    @ViewChild(ChargerTableComponent)
    private chargerTable: ChargerTableComponent;

    public addChargerButton: Button = new Button(Icon.createMaterialIcon('power'),
        "Add Charger", null, this.openCreateDialog.bind(this));
    public chargerAuditButton: Button = new Button(Icon.createMaterialIcon('trending_up'),
        "Record Audit", null, this.openAuditDialog.bind(this));

    public customer: Customer;
    public get location(): Location {
        if (this.customer == null || this.sublocationKey == null) {
            return null;
        }
        return this.customer.locations.find(loc => loc._id === this.sublocationKey.locationId);
    }
    public get sublocation(): SubLocation {
        if (this.location == null || this.sublocationKey == null) {
            return null;
        }
        return this.location.sublocations.find(s => s._id === this.sublocationKey.sublocationId);
    }

    public get customerLink(): string[] {
        return ['/customers', this.sublocationKey.customerId];
    }

    public get locationLink(): string[] {
        return [
            "/customers", this.sublocationKey.customerId,
            "locations", this.sublocationKey.locationId
        ];
    }

    public get secondaryButtons(): Button[] {
        return this.sublocation && this.sublocation.chargers && this.sublocation.chargers.length > 0
            ? [this.chargerAuditButton]
            : [];
    }

    constructor(private customerService: CustomerService,
        private chargerService: ChargerService,
        private auditService: AuditService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router) {  }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.sublocationKey = new SublocationKey(params['customerId'],
                params['locationId'],
                params['sublocationId']);
            this.customerService.get(this.sublocationKey.customerId)
                .then((c: Customer | void) => {
                    if (c) {
                        this.customer = c;
                    }
                });
        });
    }

    public openCreateDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(ChargerDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response: ChargerDialogData) => {
                if (response != null) {
                    response.charger.audits = [
                        new Audit(undefined,
                            response.energyUsageKwh, 'Initial',
                            response.charger.effectiveDate,
                            undefined, undefined)
                    ];
                    this.sublocation.chargers.push(response.charger);
                    this.chargerService.create(response.charger, this.sublocationKey)
                        .then((createdCharger: Charger) => {
                            this.sublocation.chargers.push(createdCharger);
                            this.chargerTable.addCharger(createdCharger);
                        });
                }
            });
    }

    public openAuditDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            chargers: this.sublocation.chargers
        };
        this.dialog.open(ChargerAuditDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response: AuditDialogResponse) => {
                if (response != null) {
                    const keyPrefixes: ChargerKey = new ChargerKey(
                        this.sublocationKey.customerId,
                        this.sublocationKey.locationId,
                        this.sublocationKey.sublocationId,
                        response.charger._id
                    );
                    response.audit.type = 'Audit';
                    this.auditService.create(response.audit, keyPrefixes.chargerId)
                        .then((createdAudit: Audit) => {
                            const charger = this.sublocation.chargers
                                .find(c => c._id === response.charger._id);
                            charger.audits.push(createdAudit);
                        });
                }
            });
    }

    public back() {
        this.router.navigate([
            "/customers", this.sublocationKey.customerId,
            "locations", this.sublocationKey.locationId
        ]);
    }
}
