import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/shared/components/dialog.component';
import { StateService } from 'src/app/core/services/state.service';
import { State } from 'src/app/core/models/state';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-lcfs-report-dialog',
    templateUrl: './lcfs-report-dialog.component.html'
})
export class LcfsReportDialogComponent extends DialogComponentBase implements OnInit {

    public states: State[];
    public years: number[] = [];
    public quarters: number[] = [1, 2, 3, 4];

    public get confirmButtonLabel(): string {
        return 'Download';
    }

    constructor(public dialogRef: MatDialogRef<LcfsReportDialogComponent>,
        private formBuilder: UntypedFormBuilder,
        private stateService: StateService) {
        super(dialogRef, false);
    }

    ngOnInit() {
        this.stateService.get()
            .then((s: State[]) => {
                this.states = [new State('All States', null), ...s];
            });

        const today: moment.Moment = moment();
        let year: number = today.year();
        while (year >= 2017) {
            this.years.push(year--);
        }

        let defaultQuarter: number = today.quarter() - 1;
        let defaultYear: number = today.year();
        if (defaultQuarter < 1) {
            defaultQuarter = Math.max(...this.quarters);
            defaultYear = today.year() - 1;
        }
        this.form = this.formBuilder.group({
            state: [null],
            year: [defaultYear, [Validators.required]],
            quarter: [defaultQuarter, [Validators.required]]
        });
    }

    protected getResponse(): any {
        const runDate: moment.Moment = moment()
            .year(this.form.value.year)
            .quarter(this.form.value.quarter)
            .endOf("quarter");
        return {
            state: this.form.value.state,
            runDate: runDate
        };
    }
}
