import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SublocationDialogComponent } from './sublocation-dialog.component';
import { DialogComponentBase } from '../../shared/components/dialog.component';
import { ChargerDialogData } from '../models/charger-dialog-data';
import { Charger } from '../models/charger';
import * as moment from 'moment';

@Component({
    selector: 'bet-charger-dialog',
    templateUrl: './charger-dialog.component.html',
})
export class ChargerDialogComponent extends DialogComponentBase implements OnInit {

    public chargerTypes: string[] = ['Forklift', 'Truck Refrigeration Unit'];

    constructor(public dialogRef: MatDialogRef<SublocationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ChargerDialogData,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data == null);
    }

    public ngOnInit() {
        let energyUsageValidators = [];
        if (this.isCreating) {
            energyUsageValidators = [ Validators.required ];
        }
        const hasChargerData: boolean = this.data != null && this.data.charger != null;
        this.form = this.formBuilder.group({
            charger: this.formBuilder.group({
                modelNumber: [!hasChargerData ? '' : this.data.charger.modelNumber, [ Validators.required ]],
                serialNumber: [!hasChargerData ? '' : this.data.charger.serialNumber, [ Validators.required ]],
                type: [!hasChargerData ? '' : this.data.charger.type, [ Validators.required ]],
                effectiveDate: [!hasChargerData ? '' : this.data.charger.effectiveDate, [ Validators.required ]],
                expireDate: [!hasChargerData ? '' : this.data.charger.expireDate, []],
            }),
            energyUsageKwh: [this.data == null ? '' : this.data.energyUsageKwh, energyUsageValidators]
        });
    }

    protected getResponse(): ChargerDialogData {
        return new ChargerDialogData(new Charger(
            this.data == null || this.data.charger == null
                ? undefined
                : this.data.charger._id,
            this.form.value.charger.modelNumber,
            this.form.value.charger.serialNumber,
            this.form.value.charger.type,
            undefined,
            this.getDateValue(this.form.value.charger.effectiveDate),
            this.getDateValue(this.form.value.charger.expireDate)
        ),
        this.form.value.energyUsageKwh);
    }

    private getDateValue(value: any): Date {
        if (value == null) {
            return null;
        }
        return moment.isMoment(value) ? value.toDate() : value;
    }
}
