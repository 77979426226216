<div  class="detail-header">
  <div class="detail-header-title">
      <h2 i18n>Customers</h2>
    </div>
</div>
<bet-customer-table [customers]="customers?.customers"></bet-customer-table>
<button mat-fab color="primary" class="md-fab-bottom-right" (click)="openCreateDialog()"
        matTooltip="Add Customer" matTooltipPosition="left">
  <mat-icon aria-label="Add customer">add</mat-icon>
</button>
