import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TableComponentBase } from '../../shared/components/table.component';
import { Audit } from '../models/audit';
import { Column, NumericColumn } from '../../shared/models/column';
import { DateFormatter } from '../../core/services/date.service';
import { RowAction } from '../../shared/models/row-action';
import { AuditService } from '../services/audit.service';
import { ChargerKey } from '../models/charger-key';
import { ChargerAuditDialogComponent } from './charger-audit-dialog.component';
import { Charger } from '../models/charger';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'bet-charger-audit-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['../../shared/components/table.component.scss']
})
export class ChargerAuditTableComponent extends TableComponentBase<Audit> implements OnInit {

    private _charger: Charger;

    @Input()
    public get chargerKey(): ChargerKey {
        return this.prefixKey;
    }
    public set chargerKey(v: ChargerKey) {
        this.prefixKey = v;
    }
    @Input()
    public get charger(): Charger {
        return this._charger;
    }
    public set charger(c: Charger) {
        this._charger = c;
        this.populateRows();
    }

    protected tableId = 'charger-audit-table';
    protected editDialogComponentRef = ChargerAuditDialogComponent;
    protected prefixKey: ChargerKey;
    protected get detailCollection(): Audit[] {
        return this.charger == null ? [] : this.charger.audits;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Audit';
    }
    public columns: Column<Audit>[] = [
        new Column<Audit>('_id', 'ID', false),
        new NumericColumn<Audit>('energyUsageKwh', 'Quarterly Energy Usage (kWh)', true, false, 'kWh'),
        new Column<Audit>('type', 'Audit Type', false),
        new Column<Audit>('auditDate', 'Audit Date', true, DateFormatter.getLocaleDateString),
        new Column<Audit>('updateDate', 'Update Date', false, DateFormatter.getLocaleDateString),
        new Column<Audit>('createDate', 'Create Date', false, DateFormatter.getLocaleDateString),
    ];
    public rowActions: RowAction<Audit>[] = [
        new RowAction<Audit>('delete', 'Delete Audit', this.delete.bind(this)),
        new RowAction<Audit>('edit', 'Edit Audit', this.openEditDialog.bind(this))
    ];

    constructor(auditService: AuditService,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(auditService, authService, userService, confirmationService, dialog);
    }

    protected getDeleteConfirmationMessage(row: Audit): string {
        const formattedDate: string = DateFormatter.getLocaleDateString(row.auditDate);
        return `Are you sure you want to delete the ${row.energyUsageKwh} kWh audit from ${formattedDate}?`;
    }

    public addRow(newRow: Audit) {
        super.addRow(newRow);
    }

    protected getEditData(row: Audit): any {
        return {
            chargers: [this.charger],
            audit: row
        };
    }
}
