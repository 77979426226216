import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CustomerDialogComponent, CustomerDialogData } from './customer-dialog.component';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { CustomerTableComponent } from './customer-table.component';
import { Customers } from '../models/customers';
import { SpinnerOverlayService } from 'src/app/core/services/spinner-overlay.service';

@Component({
    selector: 'bet-customer-overview',
    templateUrl: './customer-overview.component.html',
    styleUrls: ['./detail.component.scss', 'customer-overview.component.scss']
})
export class CustomerOverviewComponent implements OnInit {
    public customers: Customers;

    @ViewChild(CustomerTableComponent)
    private customerTable: CustomerTableComponent;

    constructor(private customerService: CustomerService,
        private spinnerService: SpinnerOverlayService,
        private dialog: MatDialog) {

    }

    public ngOnInit() {
        setTimeout(() => {
            this.loadCustomers();
        });
    }

    private loadCustomers() {
        this.spinnerService.showSpinner();
        this.customerService.getAll()
            .then((customers: Customer[]) => {
                this.customers = new Customers(customers);
                this.spinnerService.hideSpinner();
            });
    }

    public openCreateDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = new CustomerDialogData(this.customers.customers);

        this.dialog.open(CustomerDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((newCustomer: Customer) => {
                if (newCustomer != null) {
                    this.customerService.create(newCustomer)
                        .then((createdCustomer: Customer) => this.customerTable.addCustomer(createdCustomer));
                }
            });
    }
}
