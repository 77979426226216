import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AuthService } from './services/auth.service';

export function initializeAuth(authService: AuthService) {
    return () => authService.loadCurrentUser();
}

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAuth,
            deps: [
                AuthService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ]
})
export class CoreModule { }
