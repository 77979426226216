<h2 mat-dialog-title i18n>Sub-Location</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Sub-Location Name" i18n-placeholder
               formControlName="name" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>