import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service';
import { Customer } from '../models/customer';
import * as XLSX from 'xlsx';
import * as moment from 'moment/moment';
import { Location } from '../models/location';
import { HeadingColumn } from '../models/heading-column';
import { Constants } from '../constants';
import { Charger } from '../models/charger';

type AOA = any[][];

@Injectable({
    providedIn: 'root'
})
export class LowCarbonFuelStandardReportExporterService {
    private defaultWidth = 100;
    private headingColumns: HeadingColumn[] = [
        new HeadingColumn('OrganizationFein', this.defaultWidth),
        new HeadingColumn('Year', this.defaultWidth / 2),
        new HeadingColumn('Quarter', this.defaultWidth / 2),
        new HeadingColumn('FuelPathwayCode', this.defaultWidth * 1.5),
        new HeadingColumn('Application', this.defaultWidth * 3),
        new HeadingColumn('TransactionType', this.defaultWidth * 1.5),
        new HeadingColumn('TransactionNumber', this.defaultWidth),
        new HeadingColumn('TransactionDate', this.defaultWidth),
        new HeadingColumn('Amount', this.defaultWidth),
        new HeadingColumn('BusinessPartnerID', this.defaultWidth),
        new HeadingColumn('EPACompanyID', this.defaultWidth),
        new HeadingColumn('EPAFacilityID', this.defaultWidth),
        new HeadingColumn('PhysicalPathwayCode', this.defaultWidth),
        new HeadingColumn('AggregationIndicator', this.defaultWidth),
        new HeadingColumn('Description', this.defaultWidth * 2),
        new HeadingColumn('FSEID', this.defaultWidth)
    ];

    constructor(private customerService: CustomerService) {

    }

    public export(state: string, runDate: moment.Moment) {
        this.customerService.getAll().then((customers: Customer[]) => {
		  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.getWorksheetData(customers, state, runDate));
            this.applyFormatting(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Quarterly_Report');
            const now: moment.Moment = moment();
            const timestamp: string = now.format("YYYYMMDD_HHmmss");
		  XLSX.writeFile(workbook, `BE_ReportingUpload_${runDate.quarter()}q${runDate.year()}_${timestamp}.xlsx`);
        });
    }

    private applyFormatting(worksheet: XLSX.WorkSheet) {
        let columns: XLSX.ColInfo[] = worksheet["!cols"];
        if (columns == null) {
            columns = worksheet['!cols'] = [];
        }
        for (const headingColumn of this.headingColumns) {
            columns.push({
                wpx: headingColumn.widthPx
            });
        }
    }

    private getWorksheetData(customers: Customer[], state: string, runDate: moment.Moment): AOA {
        const rows: AOA = [];
        let transactionId = 1;

        const headings: string[] = this.headingColumns.map(h => h.label);
        rows.push(headings);

        for (const customer of customers) {
            const filteredLocations: Location[] = customer.locations
                .filter(loc => state == null || state.length === 0 ||
          loc.address != null && loc.address.state === state);
            for (const location of filteredLocations) {
                const row: any[] = this.getChargerRow(location, runDate, transactionId);
                if (row != null) {
                    rows.push(row);
                    transactionId++;
                }
            }
        }

        return rows;
    }

    private getChargerRow(location: Location,
        runDate: moment.Moment,
        transactionId: number): any[] {

        const quarterStartDate = runDate.clone().startOf("quarter");
        const quarterEndDate = runDate.clone().endOf("quarter");
        const quarterMidPoint: Date = new Date((quarterStartDate.toDate().getTime() + quarterEndDate.toDate().getTime()) / 2);

        const row: any[] = [];
        row.push(Constants.fein);
        row.push(runDate.year());
        row.push(runDate.quarter());
        row.push('ElectricityELC002_1');
        row.push('HDV/Off-Road - Electricity Forklifts');
        row.push('Forklift Electricity Fueling');
        row.push(("0000" + transactionId).slice(-4));
        row.push(quarterMidPoint);
        const energyUsage: number = location.getEnergyUsageKwh("Forklift", runDate);
        if (energyUsage == 0) {
            console.log(`No energy usage: ${location.fuelSupplyEquipmentId}`);
        }
        row.push(energyUsage.toFixed(2));
        row.push('00-0000000');
        row.push('');
        row.push('');
        row.push('PHY14');
        row.push('TRUE');
        row.push('');
        row.push(location.fuelSupplyEquipmentId);
        return row;
    }

    private getApplication(charger: Charger): string {
        return ;
    }
}
