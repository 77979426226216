<h2 mat-dialog-title>Forklift</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Make" i18n-placeholder
               formControlName="make" />
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Model" i18n-placeholder
               formControlName="model" />
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Model Year" i18n-placeholder
               formControlName="modelYear" />
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Serial Number" i18n-placeholder
               formControlName="serialNumber" />
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Fuel Type"  i18n-placeholder
                    formControlName="fuelType">
            <mat-option *ngFor="let f of fuelTypes" [value]="f.value">
                {{f.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid">
        {{confirmButtonLabel}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>