import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable({
    providedIn: 'root'
})
export class GravatarService {
    public getImageUrl(email: string, imageSize: number): string {
        const emailHash: string | Int32Array = Md5.hashStr(email);
        const defaultImage = 'mp';
        const maxRating = 'pg';
        return `https://www.gravatar.com/avatar/${emailHash}?s=${imageSize}&r=${maxRating}&d=${defaultImage}`;
    }
}
