import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from 'src/app/shared/components/dialog.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteSettings } from 'src/app/core/models/site-settings';

@Component({
    selector: 'app-site-settings-dialog',
    templateUrl: './site-settings-dialog.component.html',
    styleUrls: ['./site-settings-dialog.component.scss']
})
export class SiteSettingsDialogComponent extends DialogComponentBase implements OnInit {

    constructor(public dialogRef: MatDialogRef<SiteSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private settings: SiteSettings,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, true);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            accountAddress: [this.settings == null ? '' : this.settings.accountAddress, [ Validators.required, Validators.email ]],
            accountPassword: [this.settings == null ? '' : this.settings.accountPassword], // Not required since it's not pre-populated
            sendingAddress: [this.settings == null ? '' : this.settings.sendingAddress, [ Validators.required, Validators.email ]]
        });
    }
}
