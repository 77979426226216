import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '../models/location';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Note } from '../models/note';
import * as moment from 'moment/moment';

@Component({
    selector: 'bet-note-dialog',
    templateUrl: './note-dialog.component.html'
})
export class NoteDialogComponent implements OnInit {

    public static get defaultWidth(): string {
        return '600px';
    }

    public closeButtonText: string;
    public form: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<NoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public note: Note,
        private formBuilder: UntypedFormBuilder) {

    }

    public ngOnInit() {
        if (this.note == null) {
            this.note = Note.createEmpty();
            this.closeButtonText = 'Create';
        } else {
            this.closeButtonText = 'Save';
        }

        this.form = this.formBuilder.group({
            subject: [this.note.subject, [ Validators.required ]],
            content: [this.note.content, [ Validators.required ]],
            date: [this.note.date || new Date(), [ Validators.required ]]
        });
    }

    public create() {
        const dateValue = this.form.get("date").value;
        this.form.get('date').setValue(moment.isMoment(dateValue) ? dateValue.toDate() : dateValue);
        this.dialogRef.close(this.form.value);
    }

    public cancel() {
        this.dialogRef.close();
    }
}
