import { Charger } from "./charger";
import { IModel } from "./IModel";
import { IChargerParent } from "./IChargerParent";
import * as moment from 'moment/moment';

export class SubLocation implements IModel, IChargerParent {

    public get quarterlyEnergyUsageKwh(): number {
        return this.getEnergyUsageKwh();
    }

    public get numberOfChargers(): number {
        return this.chargers == null ? 0 : this.chargers.length;
    }

    constructor(public _id: string,
        public name: string,
        public chargers: Charger[],
        public updateDate?: Date,
        public createDate?: Date) {

    }

    public static convertToModel(model: any): SubLocation {
        if (model == null) {
            return null;
        }
        return new SubLocation(model._id,
            model.name,
            model.chargers == null
                ? []
                : model.chargers.map(c => Charger.convertToModel(c)),
            new Date(model.updatedAt),
            new Date(model.createdAt));
    }

    public static createEmpty(): SubLocation {
        return new SubLocation(
            null,
            "",
            [],
            null,
            null
        );
    }

    public getEnergyUsageKwh(chargerTypeFilter?: string, date?: moment.Moment): number {
        const chargerTotals: number[] = this.chargers
            .filter(c => chargerTypeFilter == null ||
        chargerTypeFilter.length === 0 ||
        c.type === chargerTypeFilter)
            .map(c => c.getEnergyUsageKwh(date));
        const total: number = chargerTotals.reduce((t, v) => t + v, 0);
        return total;
    }
}
