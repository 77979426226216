import { SublocationKey } from "./sublocation-key";

export class ChargerKey extends SublocationKey {
    constructor(customerId: string,
        locationId: string,
        sublocationId: string,
        public chargerId: string) {
        super(customerId, locationId, sublocationId);
    }
}
