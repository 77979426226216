import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NotifierService } from 'src/app/core/services/notifier.service';
import { ApiHelper } from 'src/app/core/services/api-helper';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ResetTokenService {

    constructor(private http: HttpClient,
        private notifier: NotifierService,
        private router: Router) {

    }

    public sendResetRequest(email: string) {
        const request: any = {
            email: email
        };
        return this.http.post("/api/login/send-password-reset", request, { responseType: 'text', observe: 'response' })
            .toPromise()
            .then(() => {
                this.notifier.success(`An email has been sent to \'${email}\' with instructions.`);
            })
            .catch((error: any) => {
                this.notifier.error('An error occurred sending the reset request. Please try again.');
                ApiHelper.handleError(error);
            });
    }

    public resetPassword(id: string, token: string, newPassword: string) {
        return this.http.post('api/login/reset-password',
            { id: id, token: token, password: newPassword },
            { responseType: 'text', observe: 'response' })
            .toPromise()
            .then(() => {
                this.notifier.success('Your password has been reset. Please continue logging in.');
                this.router.navigate(['']);
            })
            .catch((error: any) => {
                this.notifier.error('An error occurred resetting your password. Please try again.');
                ApiHelper.handleError(error);
            });
    }
}
