export class ApiHelper {
    public static handleError(error: any) {
        const errMsg = (error.message)
            ? error.message
            : error.status
                ? `${error.status} - ${error.statusText}`
                : "Server error";
        console.error(errMsg); // log to console instead
    }
}
