import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '../models/location';
import { NameFormatter } from 'src/app/core/models/name';

@Component({
    selector: 'bet-fleet-manager-contact-dialog',
    templateUrl: './fleet-manager-contact-dialog.component.html',
    styleUrls: ['./fleet-manager-contact-dialog.component.scss']
})
export class FleetManagerContactDialogComponent {

    public get name(): string {
        return this.location == null
            ? '' 
            : NameFormatter.getFormattedName(this.location.fleetManager);
    }

    constructor(public dialogRef: MatDialogRef<FleetManagerContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public location: Location) {

    }

    public dismiss() {
        this.dialogRef.close();
    }
}
