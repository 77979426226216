import { Component } from '@angular/core';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { Confirmation } from '../../core/models/confirmation';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationDialogComponent } from './notification-dialog.component';

@Component({
    selector: 'bet-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

    public get confirmation(): Confirmation {
        return this.confirmationService.confirmation;
    }

    constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>,
        public confirmationService: ConfirmationService) {

    }

    public accept() {
        this.dialogRef.close();
        this.confirmation.onAccept();
    }

    public reject() {
        this.dialogRef.close();
        this.confirmation.onReject();
    }
}
