import { AbstractControl } from "@angular/forms";

export class PasswordValidator {
    public static passwordRequirements(control: AbstractControl) {
        if (control.value.length === 0) {
            // Password may be optional. Depend on Validators.required for requiredness.
            return null;
        }

        if (control.value.length < 8) {
            return { PasswordLength: true };
        } else {
            return null;
        }
    }

    public static matchPassword(group: AbstractControl) {
        const password = group.get("password").value;
        const confirmPassword = group.get("confirmPassword").value;
        if (password !== confirmPassword) {
            group.get('confirmPassword').setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
