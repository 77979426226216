import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { User } from '../models/user';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _loggedIn = false;
    private _isAdmin = false;
    private _currentUser: ReplaySubject<User> = new ReplaySubject<User>(1);
    private jwtHelper: JwtHelperService = new JwtHelperService();

    public get currentUser(): Observable<User> {
        return this._currentUser.asObservable();
    }
    public get isLoggedIn(): boolean {
        return this._loggedIn;
    }
    public get isAdmin(): boolean {
        return this._isAdmin;
    }

    constructor(private authTokenService: AuthTokenService,
        private userService: UserService,
        private spinnerService: SpinnerOverlayService) {

    }

    public setToken(token: string): Promise<void | User> {
        this.authTokenService.set(token);
        return this.loadCurrentUser();
    }

    public clearCurrentUser() {
        this.authTokenService.clear();
        this._loggedIn = false;
        this._isAdmin = false;
        this._currentUser.next(User.createEmpty());
    }

    public loadCurrentUser(): Promise<void | User> {
        if (!this.authTokenService.hasToken) {
            return;
        }

        this.spinnerService.showSpinner();
        const id: string = this.jwtHelper.decodeToken(this.authTokenService.get()).id;
        if (id != null && id.length > 0) {
            // The token is set, so consider the user logged in until the API returns
            // This avoids timing issues with the auth guard
            this._loggedIn = true;
        }
        return this.userService.auth()
            .then(u => {
                if (u == null) {
                    this.clearCurrentUser();
                } else {
                    this.setCurrentUser(u);
                }
                this.spinnerService.hideSpinner();
                return u;
            })
            .catch(() => {
                this.clearCurrentUser();
                this.spinnerService.hideSpinner();
            });
    }

    private setCurrentUser(decodedUser) {
        this._loggedIn = true;
        if (decodedUser.role === 'admin') {
            this._isAdmin = true;
        } else {
            this._isAdmin = false;
        }
        this._currentUser.next(decodedUser as User);
        delete decodedUser.role;
    }
}
