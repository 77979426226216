import { Location } from './location';
import { IModel } from './IModel';
import { IChargerParent } from './IChargerParent';
import * as moment from 'moment/moment';

export class Customer implements IModel, IChargerParent {

    public get numberOfLocations(): number {
        return this.locations.length;
    }

    public get numberOfSublocations(): number {
        return this.locations
            .map(loc => loc.numberOfSublocations)
            .reduce((t, v) => t + v, 0);
    }

    public get numberOfForklifts(): number {
        return this.locations
            .map(c => c.numberOfForklifts)
            .reduce((t, v) => t + v, 0);
    }

    public get numberOfChargers(): number {
        return this.locations
            .map(loc => loc.numberOfChargers)
            .reduce((t, v) => t + v, 0);
    }

    public get quarterlyEnergyUsageKwh(): number {
        return this.getEnergyUsageKwh();
    }

    public get formattedAge(): string {
        const years: number = this.ageYears;
        const months: number = this.ageRemainingMonths;
        const yearLabel: string = years === 1 ? "year" : "years";
        const monthLabel: string = months === 1 ? "month" : "months";
        return `${this.ageYears} ${yearLabel}, ${this.ageRemainingMonths} ${monthLabel}`;
    }

    public get ageYears(): number {
        const now = moment(new Date());
        const startDate = moment(this.startDate);
        return now.diff(startDate, 'years');
    }

    public get ageRemainingMonths(): number {
        const now = moment(new Date());
        const startDate = moment(this.startDate);
        return now.diff(startDate, 'months') % 12;
    }

    constructor(
    /** The unique db ID */
        public _id: string,
        /** A unique, typically sequential, ID used for generating the location serial number */
        public customerId: number,
        public companyName: string,
        public startDate: Date,
        public locations: Location[],
        public createDate: Date) {

    }

    public static convertToModel(model: any): Customer {
        if (model == null) {
            return null;
        }
        return new Customer(model._id,
            model.customerId,
            model.companyName,
            new Date(model.startDate),
            model.locations.map(v => Location.convertToModel(v)),
            new Date(model.createdAt));
    }

    public static createEmpty(): Customer {
        return new Customer(
            null,
            0,
            "",
            null,
            [],
            null);
    }

    public getEnergyUsageKwh(chargerTypeFilter?: string, date?: moment.Moment): number {
        return this.locations
            .map(loc => loc.getEnergyUsageKwh(chargerTypeFilter, date))
            .reduce((t, v) => t + v, 0);
    }
}
