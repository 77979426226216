import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'bet-sublocation-import-dialog',
    templateUrl: './sublocation-import-dialog.component.html'
})
export class SublocationImportDialogComponent implements OnInit {

    public form: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<SublocationImportDialogComponent>,
        private formBuilder: UntypedFormBuilder) {

    }

    public ngOnInit() {
        this.form = this.formBuilder.group({
            file: [undefined, [ Validators.required ]],
        });
    }

    public create() {
        this.dialogRef.close(this.form.value);
    }

    public cancel() {
        this.dialogRef.close();
    }
}
