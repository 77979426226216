import { IModel } from "./IModel";

export class NoteFilter {
    constructor(public customerId: string,
        public locationID?: string) {

    }
}

export class Note implements IModel {
    constructor(public _id: string,
        public customerId: string,
        public locationID: string,
        public subject: string,
        public content: string,
        public date: Date) {

    }

    public static convertToModel(model: any): Note {
        if (model == null) {
            return null;
        }
        return new Note(model._id,
            model.customerId,
            model.locationID,
            model.subject,
            model.content,
            new Date(model.date));
    }

    public static createEmpty(): Note {
        return new Note(undefined, undefined, undefined, '', '', null);
    }
}
