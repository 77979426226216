<ng-container *ngIf="isLoggedIn">
  <button mat-flat-button color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>cloud_download</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item (click)="requestLcfsQuarterlyReport()">
      <span i18n>LCFS Quarterly Report</span>
    </button>
    <button mat-menu-item (click)="downloadFfrReport()">
      <span i18n>FSE Registration Report</span>
    </button>
  </mat-menu>
</ng-container>