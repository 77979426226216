import { IModel } from "./IModel";

export class CustomerDocument implements IModel {

    constructor(public _id: string,
        public fileName: string,
        public originalFileName: string,
        public description: string,
        public fileSizeBytes: number,
        public uploadDate: Date) {

    }

    public static convertToModel(model: any): CustomerDocument {
        if (model == null) {
            return null;
        }
        return new CustomerDocument(model._id,
            model.filename,
            model.metadata && model.metadata.originalFileName
                ? model.metadata.originalFileName
                : '',
            model.metadata && model.metadata.description
                ? model.metadata.description
                : '',
            model.length,
            new Date(model.uploadDate));
    }

    public static createEmpty(): CustomerDocument {
        return new CustomerDocument(undefined, '', '', '', 0, null);
    }
}
