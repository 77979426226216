<h2 mat-dialog-title i18n>Email Settings</h2>

<mat-dialog-content [formGroup]="form">
  <mat-form-field>
      <input matInput placeholder="Account Address" i18n-placeholder
              formControlName="accountAddress" type="email" />
  </mat-form-field>
  <mat-form-field>
      <input matInput placeholder="Account Password" i18n-placeholder
              formControlName="accountPassword" type="password" />
  </mat-form-field>
  <mat-form-field>
      <input matInput placeholder="Sending Address" i18n-placeholder
              formControlName="sendingAddress" type="email" />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
      Save
  </button>
  <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>