export class Confirmation {
    constructor(public title: string,
        public message: string,
        public acceptLabel: string,
        public onAccept: () => void,
        public rejectLabel: string = 'Cancel',
        public onReject: () => void = () => {}) {

    }
}
