import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export class DialogComponentBase {

    public form: UntypedFormGroup;

    public get confirmButtonLabel(): string {
        return this.isCreating
            ? 'Create'
            : 'Save';
    }

    constructor(public dialogRef: MatDialogRef<any>,
        public isCreating: boolean) {

    }

    protected getResponse(): any {
        return this.form.value;
    }

    public save() {
        this.dialogRef.close(this.getResponse());
    }

    public cancel() {
        this.dialogRef.close();
    }
}
