import { Component, Input, ViewChild } from '@angular/core';
import { Column, NumericColumn, ActionColumn } from '../../shared/models/column';
import { RowAction } from '../../shared/models/row-action';
import { Location } from '../models/location';
import { Router } from '@angular/router';
import { DateFormatter } from '../../core/services/date.service';
import { Customer } from '../models/customer';
import { TableComponentBase } from '../../shared/components/table.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocationService } from '../services/location.service';
import { ConfirmationService } from '../../core/services/confirmation.service';
import { LocationDialogComponent, LocationDialogData } from './location-dialog.component';
import { CustomerKey } from '../models/customer-key';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { NameFormatter } from 'src/app/core/models/name';
import { FleetManagerContactDialogComponent } from './fleet-manager-contact-dialog.component';

@Component({
    selector: 'bet-location-table',
    templateUrl: '../../shared/components/table.component.html',
    styleUrls: ['./location-table.component.scss', '../../shared/components/table.component.scss']
})
export class LocationTableComponent extends TableComponentBase<Location> {
    private _customer: Customer;
    @Input()
    public get customer(): Customer {
        return this._customer;
    }
    public set customer(c: Customer) {
        this._customer = c;
        this.prefixKey = this.customer == null ? null : new CustomerKey(this.customer._id);
        this.loadLocations();
    }

    protected tableId = 'location-table';
    protected editDialogComponentRef = LocationDialogComponent;
    protected prefixKey: CustomerKey;
    protected get detailCollection(): Location[] {
        return this.customer.locations;
    }
    protected get deleteConfirmationTitle(): string {
        return 'Delete Location';
    }
    public columns: Column<Location>[] = [
        new Column<Location>('_id', 'ID', false),
        new Column<Location>('name', 'Location Name', true),
        new Column<Location>('fuelSupplyEquipmentId', 'FSE Registration ID', true),
        new ActionColumn<Location>('fleetManager', 'Fleet Manager', true, this.openContactDialog.bind(this), NameFormatter.getFormattedName),
        new Column<Location>('utilityServiceProvider', 'Utility', true),
        new NumericColumn<Location>('numberOfForklifts', 'Total Number of Forklifts', true),
        new NumericColumn<Location>('numberOfSublocations', 'Total Number of Sub-Locations', true),
        new NumericColumn<Location>('numberOfChargers', 'Total Number of Chargers', true),
        new NumericColumn<Location>('quarterlyEnergyUsageKwh', 'Quarterly Energy Usage (kWh)', true, true, 'kWh'),
        new Column<Location>('startDate', 'Date Added', false, DateFormatter.getQuarterDateString),
    ];
    public rowActions: RowAction<Location>[] = [
        new RowAction<Location>('delete', 'Delete Location', this.delete.bind(this)),
        new RowAction<Location>('edit', 'Edit Location', this.openEditDialog.bind(this)),
        new RowAction<Location>('launch', 'Location Details', this.redirectToLocationDetails.bind(this))
    ];

    constructor(locationService: LocationService,
        private router: Router,
        authService: AuthService,
        userService: UserService,
        confirmationService: ConfirmationService,
        dialog: MatDialog) {
        super(locationService, authService, userService, confirmationService, dialog);
    }

    protected getDeleteConfirmationMessage(row: Location): string {
        return `Are you sure you want to delete location '${row.name}'?`;
    }

    protected onRowDblClick(row: Location) {
        this.redirectToLocationDetails(row);
    }

    private loadLocations() {
        const locations: Location[] = this.customer == null || this.customer.locations == null
            ? []
            : this.customer.locations;
        this.setRows(locations);
    }

    private redirectToLocationDetails(location: Location) {
        this.router.navigate(['/customers', this.customer._id, 'locations', location._id]);
    }

    private openContactDialog(row: Location): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.data = this.getEditData(row);
        this.dialog.open(FleetManagerContactDialogComponent, dialogConfig);
    }

    protected getEditData(row: Location): any {
        return new LocationDialogData(this.customer, row);
    }
}
