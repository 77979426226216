<div class="table-container mat-elevation-z8">
  <h3 *ngIf="hasTitle">{{title}}</h3>
  <div class="table-controls">
    <mat-form-field class="filter-field" *ngIf="isFiltered">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" i18n-placeholder>
    </mat-form-field>
    <button mat-icon-button class="column-picker"
            matTooltip="Choose Columns" i18n-matTooltip
            (click)="showColumnPicker()">
      <mat-icon class='md-32'>more_vert</mat-icon>
    </button>
  </div>

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.label }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onClickColumn(column, row)">
        <span class="cell-label">{{ column.label }}:</span>
        <span class="cell-value">{{ column.getValue(row) }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <span *ngIf="isFirstDisplayedColumn(column)" class="footer-label" i18n>
          Total:
        </span>
        <span class="cell-label">
          {{ column.getTotal(dataSource) ? column.label + ':' : '' }}
        </span>
        <span class="cell-value">{{ column.getTotal(dataSource) }}</span>
      </mat-footer-cell>
    </ng-container>
    <ng-container [matColumnDef]="actionColumnId">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <button *ngFor="let action of rowActions" mat-icon-button
                  (click)="action.callback(row)"
                  [matTooltip]="action.label">
            <mat-icon class='md-32'>{{ action.iconName }}</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="onRowClick(row)"
              (dblclick)="onRowDblClick(row)">
    </mat-row>
    <ng-container *ngIf="hasNumericColumns">
      <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
    </ng-container>
  </mat-table>

  <div *ngIf="!hasRows" class="empty-message" i18n>No items found</div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"></mat-paginator>
</div>
