<h2 mat-dialog-title i18n>New Note</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Subject" i18n-placeholder
               formControlName="subject" />
    </mat-form-field>
    <ngx-wig placeholder="Enter note content..." i18n-placeholder
             formControlName="content">
    </ngx-wig>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Date"
                        formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="create()" [disabled]="!form.valid" i18n>
        {{closeButtonText}}
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>