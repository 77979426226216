import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '../models/location';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateService } from 'src/app/core/services/state.service';
import { State } from 'src/app/core/models/state';
import { SelectItem } from 'src/app/core/models/select-item';
import { DialogComponentBase } from 'src/app/shared/components/dialog.component';
import { Customer } from '../models/customer';

export class LocationDialogData {
    constructor(public customer?: Customer,
        public location?: Location) {

    }
}

@Component({
    selector: 'bet-location-dialog',
    templateUrl: './location-dialog.component.html',
    styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent extends DialogComponentBase implements OnInit {

    public states: State[];
    public countries: SelectItem[];

    constructor(private stateService: StateService,
        dialogRef: MatDialogRef<LocationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: LocationDialogData,
        private formBuilder: UntypedFormBuilder) {
        super(dialogRef, data == null);
    }

    public ngOnInit() {

        this.countries = [
            new SelectItem('United States', 'USA')
        ];
        this.stateService.get()
            .then((s: State[]) => {
                this.states = s;
            });

        const location: Location = this.data.location;
        const hasLocation: boolean = location != null;
        const hasAddress: boolean = hasLocation && location.address != null;
        const hasFleetManager: boolean = hasLocation && location.fleetManager != null;
        const hasCoordinates: boolean = hasLocation && location.coordinates != null;
        this.form = this.formBuilder.group({
            locationId: [!hasLocation ? this.generateId() : location.locationId, [Validators.required, LocationDialogComponent.unique(this.data)]],
            name: [!hasLocation ? '' : location.name, [ Validators.required ]],
            fuelSupplyEquipmentId: [!hasLocation ? '' : location.fuelSupplyEquipmentId],
            address: this.formBuilder.group({
                streetAddress: [hasAddress ? location.address.streetAddress : '', [Validators.required]],
                streetAddress2: [hasAddress ? location.address.streetAddress2 : ''],
                city: [hasAddress ? location.address.city : '', [Validators.required]],
                state: [hasAddress ? location.address.state : '', [Validators.required]],
                postalCode: [hasAddress ? location.address.postalCode : '', [Validators.required]],
                country: [hasAddress ? location.address.country || this.countries[0].value : '', [Validators.required]]
            }),
            coordinates: this.formBuilder.group({
                latitude: [hasCoordinates ? location.coordinates.latitude : ''],
                longitude: [hasCoordinates ? location.coordinates.longitude : '']
            }),
            fleetManager: this.formBuilder.group({
                firstName: [hasFleetManager ? location.fleetManager.firstName : ''],
                lastName: [hasFleetManager ? location.fleetManager.lastName : ''],
                phoneNumber: [hasFleetManager ? location.fleetManager.phoneNumber : ''],
                email: [hasFleetManager ? location.fleetManager.email : ''],
            }),
            utilityServiceProvider: [!hasLocation ? '' : location.utilityServiceProvider],
            startDate: [!hasLocation ? '' : location.startDate, [ Validators.required ]]
        });
    }

    private generateId(): number {
        const maxLocationId: number = Math.max(
            ...this.data.customer.locations.map(loc => loc.locationId));
        return isNaN(maxLocationId) ? 1 : maxLocationId + 1;
    }

    private static unique(data: LocationDialogData) {
        return (control: AbstractControl) => {
            const isDuplicateId: boolean = data.customer.locations
                .filter(loc => loc !== data.location)
                .map(loc => loc.locationId)
                .some(id => id === control.value);
            return isDuplicateId ? { UniqueId: isDuplicateId } : null;
        };
    }
}
