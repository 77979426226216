import { Routes } from "@angular/router";
import { AuthGuardLogin } from './core/services/auth-guard-login.service';
import { LoginComponent } from "./users/components/login.component";
import { CustomerOverviewComponent } from "./customers/components/customer-overview.component";
import { CustomerDetailComponent } from "./customers/components/customer-detail.component";
import { UserProfileComponent } from "./users/components/user-profile.component";
import { LocationDetailComponent } from "./customers/components/location-detail.component";
import { SublocationDetailComponent } from "./customers/components/sublocation-detail.component";
import { ChargerDetailComponent } from "./customers/components/charger-detail.component";
import { AuthGuardAnonymous } from "./core/services/auth-guard-anonymous.service";
import { ResetPasswordComponent } from "./users/components/reset-password.component";

export const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuardAnonymous]
    },
    {
        path: 'login/reset-password/:id/:token',
        component: ResetPasswordComponent
    },
    {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'customers',
        component: CustomerOverviewComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'customers/:id',
        component: CustomerDetailComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'customers/:customerId/locations/:locationId',
        component: LocationDetailComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'customers/:customerId/locations/:locationId/sublocations/:sublocationId',
        component: SublocationDetailComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'customers/:customerId/locations/:locationId/sublocations/:sublocationId/chargers/:chargerId',
        component: ChargerDetailComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: '',
        redirectTo: '/customers',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/customers',
        pathMatch: 'full' 
    }
];
