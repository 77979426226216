import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Address } from '../models/address';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddressDialogComponent } from './address-dialog.component';

@Component({
    selector: 'bet-address',
    templateUrl: './address.component.html',
    styleUrls: ['./summary-card.component.scss', './address.component.scss']
})
export class AddressComponent implements OnInit {
    @Input() address: Address;
    @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();

    constructor(private dialog: MatDialog) { }

    ngOnInit() {
    }

    public edit() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = this.address;

        this.dialog.open(AddressDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((address: Address) => {
                if (address != null) {
                    this.addressChange.emit(address);
                }
            });
    }
}
