import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service';
import { Customer } from '../models/customer';
import * as XLSX from 'xlsx';
import * as moment from 'moment/moment';
import { Location } from '../models/location';
import { SubLocation } from '../models/sublocation';
import { Charger } from '../models/charger';
import { Address } from '../models/address';
import { HeadingColumnGroup, HeadingColumn } from '../models/heading-column';
import { Constants } from '../constants';

type AOA = any[][];

@Injectable({
    providedIn: 'root'
})
export class FuelSupplyEquipmentRegistrationReportExporterService {

    private headingGroups: HeadingColumnGroup[] = [
        new HeadingColumnGroup('Company Information', 1, [
            new HeadingColumn('FEIN', 125)
        ]),
        // Facility info
        new HeadingColumnGroup('Fueling Facility Information', 6, [
            new HeadingColumn('Facility Name', 200),
            new HeadingColumn('Facility Street No. & Name', 175),
            new HeadingColumn('City Name', 100),
            new HeadingColumn('Zip Code', 100),
            new HeadingColumn('Latitude', 100),
            new HeadingColumn('Longitude', 100),
        ]),
        // Natural Gas
        new HeadingColumnGroup('Natural Gas (NG) Fueling Supply Equipment', 2, [
            new HeadingColumn('CNG Utility Meter# / LNG Facility ID', 200),
            new HeadingColumn('CNG Utility Name/LNG Facility Owner', 200),
        ]),
        // H2
        new HeadingColumnGroup('Hydrogen (H2) Fueling Supply Equipment', 2, [
            new HeadingColumn('H2 Dispenser ID', 100),
            new HeadingColumn('H2 Station Identifier', 125),
        ]),
        // EV
        new HeadingColumnGroup('Electric Vehicle (EV) Fueling Supply Equipment (FSE)', 2, [
            new HeadingColumn('EV-FSE Serial #', 125),
            new HeadingColumn('EV-FSE Manufacturer', 150)
        ]),
    ];

    constructor(private customerService: CustomerService) {

    }

    public export() {
        this.customerService.getAll().then((customers: Customer[]) => {
            const rows: AOA = [
                this.getGroupHeadings(),
                this.getColumnHeadings(),
                ...this.getCustomerWorksheetData(customers)
            ];
		  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
            this.applyFormatting(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Facility-FSE');

            const now: moment.Moment = moment();
            const timestamp: string = now.format("YYYYMMDD_HHmmss");
		  XLSX.writeFile(workbook, `LCFS_FFR_BeyondEnergy_${timestamp}.xlsx`);
        });
    }

    private getCustomerWorksheetData(customers: Customer[]): AOA {
        const rows: any[][] = [];
        for (const customer of customers) {
            for (const location of customer.locations) {
                for (const sublocation of location.sublocations) {
                    rows.push(this.getRegistrationRow(customer, location));
                }
            }
        }
        return rows;
    }

    private getGroupHeadings(): string[] {
        const row: string[] = [];
        for (const headingGroup of this.headingGroups) {
            row.push(...this.getSpanningColumns(headingGroup.label, headingGroup.span));
        }
        return row;
    }

    private applyFormatting(worksheet: XLSX.WorkSheet) {
        this.mergeGroupHeadingCells(worksheet);
        this.setGroupHeadingHeight(worksheet);
        this.setColumnWidths(worksheet);
    }

    private mergeGroupHeadingCells(worksheet: XLSX.WorkSheet) {
        let merges: XLSX.Range[] = worksheet['!merges'];
        if (merges == null) {
            merges = worksheet['!merges'] = [];
        }
        let columnCharCode: number = 'A'.charCodeAt(0);
        for (const headingGroup of this.headingGroups) {
            if (headingGroup.span <= 0) {
                continue;
            } else if (headingGroup.span === 1) {
                columnCharCode++;
                continue;
            }
            const startColumn: string = String.fromCharCode(columnCharCode);
            columnCharCode += headingGroup.span;
            const endColumn: string = String.fromCharCode(columnCharCode - 1);
            merges.push(XLSX.utils.decode_range(`${startColumn}1:${endColumn}1`));
        }
    }

    private setGroupHeadingHeight(worksheet: XLSX.WorkSheet) {
        let rows: XLSX.RowInfo[] = worksheet['!rows'];
        if (rows == null) {
            rows = worksheet['!rows'] = [];
        }
        rows.push({
            hpx: 40
        });
    }

    private setColumnWidths(worksheet: XLSX.WorkSheet) {
        let columns: XLSX.ColInfo[] = worksheet["!cols"];
        if (columns == null) {
            columns = worksheet['!cols'] = [];
        }
        for (const headingGroup of this.headingGroups) {
            for (const headingColumn of headingGroup.headingColumns) {
                columns.push({
                    wpx: headingColumn.widthPx
                });
            }
        }
    }

    private getColumnHeadings(): string[] {
        const columnHeadings: string[] = [];
        for (const headingGroup of this.headingGroups) {
            columnHeadings.push(...headingGroup.headingColumns.map(c => c.label));
        }
        return columnHeadings;
    }

    private getSpanningColumns(value: any, columnSpan: number): any[] {
        const columns: any[] = [value];
        for (let i = 0; i < columnSpan - 1; i++) {
            columns.push('');
        }
        return columns;
    }

    private getRegistrationRow(customer: Customer,
        location: Location): any[] {
        const row: any[] = [];
        row.push(Constants.fein);
        row.push(location.name);
        row.push(...this.getAddressColumns(location.address));
        row.push(location.coordinates != null ? location.coordinates.latitude : '');
        row.push(location.coordinates != null ? location.coordinates.longitude : '');
        for (let i = 0; i < 4; i++) {
            // Skip columns for data that doesn't apply
            row.push('');
        }
        let serialNumber = '';
        if (customer.customerId && location.locationId) {
            const locationId: string = ("00" + location.locationId).slice(-2);
            serialNumber = `BE-${customer.customerId}-${locationId}`;
        }
        row.push(serialNumber);
        row.push('n/a');
        return row;
    }

    private getAddressColumns(address: Address): any[] {
        const columns: any[] = [];
        let streetAddress = '';
        if (address != null) {
            const addressParts: string[] = [];
            if (address.streetAddress) {
                addressParts.push(address.streetAddress);
            }
            if (address.streetAddress2) {
                addressParts.push(address.streetAddress2);
            }
            streetAddress = addressParts.join(',');
        }
        columns.push(streetAddress);
        columns.push(address == null ? '' : address.city);
        columns.push(address == null ? '' : address.postalCode);
        return columns;
    }
}
