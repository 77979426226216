import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'bet-document-upload-dialog',
    templateUrl: './document-upload-dialog.component.html'
})
export class DocumentUploadDialogComponent implements OnInit {

    public form: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
        private formBuilder: UntypedFormBuilder) {

    }

    public ngOnInit() {
        this.form = this.formBuilder.group({
            file: [undefined, [ Validators.required ]],
            description: ''
        });
    }

    public create() {
        this.dialogRef.close(this.form.value);
    }

    public cancel() {
        this.dialogRef.close();
    }
}
