import { Address } from "./address";
import { SubLocation } from "./sublocation";
import { Forklift } from "./forklift";
import { IModel } from "./IModel";
import { IChargerParent } from "./IChargerParent";
import { Coordinates } from "./coordinates";
import { FleetManager } from "./fleet-manager";
import * as moment from 'moment/moment';

export class Location implements IModel, IChargerParent {

    public get numberOfSublocations(): number {
        return this.sublocations.length;
    }

    public get numberOfForklifts(): number {
        return this.forklifts.length;
    }

    public get numberOfChargers(): number {
        return this.sublocations
            .map(s => s.chargers.length)
            .reduce((t, v) => t + v, 0);
    }

    public get quarterlyEnergyUsageKwh(): number {
        return this.getEnergyUsageKwh();
    }

    constructor(
    /** The unique db ID */
        public _id: string,
        /** A sequential ID, used for generating the location serial number */
        public locationId: number,
        public name: string,
        public fuelSupplyEquipmentId: string,
        public coordinates: Coordinates,
        public address: Address,
        public fleetManager: FleetManager,
        public startDate: Date,
        public utilityServiceProvider: string,
        public sublocations: SubLocation[],
        public forklifts: Forklift[],
        public createDate: Date) {

    }

    public static convertToModel(model: any): Location {
        if (model == null) {
            return null;
        }
        return new Location(model._id,
            model.locationId,
            model.name,
            model.fuelSupplyEquipmentId,
            Coordinates.convertToModel(model.coordinates),
            Address.convertToModel(model.address),
            FleetManager.convertToModel(model.fleetManager),
            model.startDate,
            model.utilityServiceProvider,
            model.sublocations == null
                ? []
                : model.sublocations.map(s => SubLocation.convertToModel(s)),
            model.forklifts == null
                ? []
                : model.forklifts.map(f => Forklift.convertToModel(f)),
            new Date(model.createdAt));
    }

    public static createEmpty(): Location {
        return new Location(
            null,
            0,
            "",
            "",
            Coordinates.createEmpty(),
            Address.createEmpty(),
            FleetManager.createEmpty(),
            null,
            "",
            [],
            [],
            null
        );
    }

    public getEnergyUsageKwh(chargerTypeFilter?: string, date?: moment.Moment): number {
        const sublocationTotals: number[] = this.sublocations
            .map(s => s.getEnergyUsageKwh(chargerTypeFilter, date));
        const total: number = sublocationTotals.reduce((t, v) => t + v, 0);
        return total;
    }
}
