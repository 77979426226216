import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../core/services/base.service';
import { SubLocation } from '../models/sublocation';
import { LocationKey } from '../models/location-key';

@Injectable({
    providedIn: 'root'
})
export class SublocationService extends BaseService<SubLocation> {
    protected slug = 'sublocations';

    constructor(http: HttpClient) {
        super(http);
    }

    protected getRootUrl(keyPrefixes: LocationKey): string {
        const customerId: string = keyPrefixes.customerId;
        const locationId: string = keyPrefixes.locationId;
        return `/api/customers/${customerId}/locations/${locationId}/sublocations`;
    }

    protected convertToModel(model: any): SubLocation {
        return SubLocation.convertToModel(model);
    }
}
