import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer } from '../models/customer';
import { BaseService } from '../../core/services/base.service';
import { NoteService } from './note.service';
import { DocumentService } from './document.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends BaseService<Customer> {
    protected slug = 'customers';

    constructor(http: HttpClient,
        private noteService: NoteService,
        private documentService: DocumentService) {
        super(http);
    }

    protected convertToModel(model: any): Customer {
        return Customer.convertToModel(model);
    }

    public delete(id: string, keyPrefixes?: any): Promise<void | Object> {
        return this.documentService.deleteMany(id)
            .then(() => this.noteService.deleteMany(id))
            .then(() => super.delete(id, keyPrefixes));
    }
}
