<h2 mat-dialog-title i18n>Charger Audit</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Charger" i18n-placeholder
               formControlName="charger" [matAutocomplete]="auto" 
               [readonly]="!isCreating" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
            <mat-option *ngFor="let charger of filteredChargers | async" [value]="charger">
                <span class="charger-model-number">{{ charger.modelNumber }}</span>
                <span class="charger-serial-number">{{ charger.serialNumber }}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div formGroupName="audit">
        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Audit Date"
                            formControlName="auditDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Quarterly Energy Usage (kWh)" i18n-placeholder
                [attr.cdkFocusInitial]="hasPresetCharger ? '' : null"
                formControlName="energyUsageKwh" type="number" />
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" i18n>
        Save
    </button>
    <button mat-button (click)="cancel()" i18n>Cancel</button>
</mat-dialog-actions>